import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getTabsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { deleteTab } from "../../../api";
import ModalConfirmation from "../ui/modal";
import classnames from "classnames";
const schema = createSchema({});
const values = {
  type: 0,
};

const BannerList = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams()
  const { watch, setValue } = useHookForm(values, schema);
  const QueryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteBanner, setDeleteBanner] = useState("");
  const selectedTab = Number(searchParams.get("type")) || 0
  const location = useLocation();

  localStorage.setItem("path", location.pathname);

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setDeleteBanner(id);
  }

  const { data: tabs, isFetched } = useQuery({
    ...getTabsQuery({
      type: watch("type"),
    }),
  });
  useEffect(() => {
    setValue("type", Number(selectedTab));
  }, [selectedTab, setValue]);
  const columns = useMemo(
    () => [
      {
        Header: t("type"),
        accessor: "type",
        Cell: (tab) => {
          return <span>{serviceType(tab.row.original.type)}</span>;
        },
      },
      {
        Header: t("name"),
        accessor: "name",
      },
      {
        Header: t("order"),
        accessor: "order",
      },
      {
        Header: t("state"),
        accessor: "state",
        Cell: (tab) => {
          return <span>{serviceState(tab.row.original.state)}</span>;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={watch("type") !== 0 ? `/service/${cell.row.original.id}` : `/tab/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to={`/service/edit/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-edit-line fs-4 p-1 fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <btn
                  className="ri-delete-bin-5-line fs-4 p-1"
                  onClick={() => {
                    toggleModal(cell.row.original.id);
                  }}
                  style={{ cursor: "pointer", color: "#FF4C4C" }}
                />
              </li>
              {/* <li className="list-inline-item">
                <Link
                  to={`/service/item/add/${cell.row.original.id}`}
                  className="text-primary d-flex align-align-items-center justify-content-between"
                >
                  <i className="ri-add-line fs-4 p-1"></i>
                  <span style={{ display: "block", margin: "auto auto" }}>
                    {t("add_element")}
                  </span>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );
  document.title = "Tabs | Taqsim";
  const moderationStatusOption = [
    { value: 0, label: "tab" },
    { value: 1, label: "service" },
    { value: 2, label: "banner" },
  ];
  const handleNavClick = (value) => {
    setSearchParams({type: value})
    setValue("type", value); 
  };
  
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Tabs")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <ModalConfirmation
                title={t("delete_confirmations")}
                isOpen={isModalOpen}
                toggleConfirmation={toggleModal}
              >
                {
                  <div className="d-flex justify-content-evenly">
                    <Button
                      color="success"
                      className="w-25"
                      onClick={() => toggleModal()}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      color="danger"
                      className="w-25"
                      onClick={() => {
                        deleteTab(deleteBanner).then(() => {
                          QueryClient.invalidateQueries("tabs");
                        });
                        toggleModal();
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </div>
                }
              </ModalConfirmation>
              <CardHeader className="card-header border-0 pb-0">
                <Row>
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <div className="d-flex gap-2 align-items-center">
                    <h5 className="card-title mb-0">{t("Tabs")}</h5>
                   <div className="d-flex gap-2">
                   <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
           
                     {moderationStatusOption.map((state) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: selectedTab === state.value },
                              "fw-semibold"
                            )}
                            onClick={() => handleNavClick(state.value)}
                            href="#"
                          >
                            {t(
                              state.label
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                   </div>
                    </div>
                    <Link
                      to={"/service/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        columns={columns}
                        data={tabs?.items || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerList;
