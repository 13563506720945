import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
  Label,
  Input as ReactInput,
  Form,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getModerationQuery, getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import { createModerationProduct } from "../../../api";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { config } from "../../../utils/config";
import Cleave from "cleave.js/react";
import { Input } from "../../../Components/atoms/input";
import ModalConfirmation from "../ui/modal";
import createSchema from "../../../helpers/createSchema";

const values = {};
const schema = createSchema({});

const ModerProductView = () => {
  const { moderId } = useParams();
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { data: product } = useQuery({
    ...getModerationQuery(moderId),
  });
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useHookForm(values, schema);
  const toggleModal = () => {
    setModal(!modal);
  };
  const handleClick = (status) => {
    setLoading(true);
    const data = {
      productId: moderId,
      status,
      comment: watch("comment") || "",
    };
    createModerationProduct(data).then(() => {
      setLoading(false);
      navigate("/moder-products");
      QueryClient.invalidateQueries(["moderations"]);
    });
  };

  useEffect(() => {
    if (product) {
      setValue("categoryName", product.category.name);
      setValue(
        "brandId",
        product?.brand?.id === 1 || product?.brand === null
          ? t("no_brand")
          : product?.brand?.name
      );
      product.names.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      product.descriptions.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      product.variations?.[0].prices.map((item) =>
        setValue(`price_${item.type}`, item.value)
      );
      product.variations?.[0].attributeValues.map((item) =>
        setValue(`value_${item.attribute?.name}`, item.value)
      );
      setValue("mxikCode", product?.mxikCode)
      setValue("packageCode", product?.packageCode)
    }
  }, [product]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  const handleButtonClick = (value) => {
    setValue("comment", value);
  };
  useEffect(() => {
    if (!modal) {
      setValue("comment", "");
    }
  }, [modal, setValue]);
  document.title = "Moder Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <ModalConfirmation
          title={t("reject_reason")}
          isOpen={modal}
          toggleConfirmation={toggleModal}
        >
          <Form
            onSubmit={handleSubmit(() => handleClick(3))}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "16px",
            }}
          >
            <Textarea
              control={control}
              name={"comment"}
              errors={errors}
              label={"comment"}
              inputProps={{
                style: {
                  width: "100%",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  fontSize: "14px",
                },
                rows: "3",
              }}
            />

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                justifyContent: "space-between",
              }}
            >
              {[
                t("reason_1"),
                t("reason_2"),
                t("reason_3"),
                t("reason_4"),
                t("reason_5"),
              ].map((reason, index) => (
                <Button
                  key={index}
                  outline
                  color="none"
                  type="button"
                  onClick={() => handleButtonClick(reason)}
                  style={{
                    flex: "1 1 calc(50% - 8px)",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "14px",
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  {reason}
                </Button>
              ))}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
              }}
            >
              <Button
                type="submit"
                style={{
                  padding: "10px 20px",
                  borderRadius: "8px",
                  color: "#fff",
                  border: "none",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                {t("save")}
              </Button>
            </div>
          </Form>
        </ModalConfirmation>

        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        {product?.id && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="w-75">
                <CardHeader>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <i
                        className="ri-arrow-left-line fs-3 cursor-pointer"
                        onClick={() => navigate(-1)}
                      ></i>
                      <h5
                        className="card-title flex-grow-1 mb-0"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        {t("products")}
                      </h5>
                    </div>
                    <div className="d-flex gap-3">
                      <Button
                        color="danger"
                        style={{ width: "150px" }}
                        outline
                        onClick={toggleModal}
                      >
                        {"✖ Отклонить товар"}
                      </Button>
                      <Button
                        color="success"
                        className="btn-animation"
                        data-text={"✔ Одобрить товар"}
                        style={{ width: "150px" }}
                        onClick={() => handleClick(1)}
                      >
                        <span>{"✔ Одобрить товар"}</span>
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 col-6">
                    <Input
                      name="categoryName"
                      control={control}
                      errors={errors}
                      label="category_name"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <Input
                      name="brandId"
                      control={control}
                      errors={errors}
                      label="brand_name"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <div className="d-flex flex-column gap-3">
                      {languages.map((lang) => {
                        const item = product?.names?.find(
                          (name) => name.languageCode === lang
                        );
                        return (
                          <div key={lang}>
                            <Label className="fw-semibold">
                              {t(`name_${item.languageCode}`)}
                            </Label>
                            <ReactInput
                              value={item ? item.text : ""}
                              readOnly
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className="d-flex flex-column gap-3">
                      {languages.map((lang) => {
                        const item = product?.descriptions?.find(
                          (name) => name.languageCode === lang
                        );
                        return (
                          <div key={lang}>
                            <Label className="fw-semibold">
                              {t(`description_${item.languageCode}`)}
                            </Label>
                            <textarea
                              className="form-control p-3"
                              value={item ? item.text : ""}
                              readOnly
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex align-items-center gap-3 flex-column">
  <div className="w-100">
  <Input  name={"mxikCode"} control={control} label={t("mxikCode")} inputProps={{
    readOnly: true
  }}/>
  </div>
  <div className="w-100">
  <Input name={"packageCode"} control={control} label={t("packageCode")} inputProps={{
    readOnly: true
  }}/>
  </div>
</div>
                    <div className="d-flex flex-column gap-3">
                      {product?.variations[0]?.prices?.map((item) => (
                        <div key={item.id}>
                          <Label className="fw-semibold">
                            {t(`${item.type === "Sale" ? "MSRP" : item.type}`)}
                          </Label>
                          <Cleave
                            placeholder="Enter numeral"
                            options={{
                              numeral: true,

                              numeralThousandsGroupStyle: "thousand",
                            }}
                            value={item.value}
                            className="form-control border-dashed p-3"
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "grid",
                        textAlign: "center",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "5px",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {product?.variations[0]?.files?.map((item) => (
                        <div
                          id="img2"
                          key={item}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <img
                            src={item?.url}
                            className="img-thumbnail rounded avatar-xxl"
                            alt={item?.id}
                            style={{
                              height: "100%",
                              width: "auto",
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="d-flex flex-column gap-3 col-6">
                    {product?.variations[0]?.attributeValues
                      ?.sort((a, b) => a.attribute.weight - b.attribute.weight)
                      ?.map(
                        (item) =>
                          item.attribute.isVisible &&
                          (!item.attribute.isValueTranslated ? (
                            <div>
                              <Label> {t(`${item?.attribute?.name}`)}</Label>
                              <ReactInput
                                className=""
                                value={item.value.trim()}
                                readOnly
                              />
                            </div>
                          ) : (
                            item.attribute.isValueTranslated && (
                              <div>
                                <Label>{item.attribute?.name}</Label>

                                <div className="d-flex align-items-center gap-3">
                                  {languages.map((lang) => {
                                    const translation =
                                      item?.valueTranslations?.find(
                                        (valueTranslation) =>
                                          valueTranslation.languageCode === lang
                                      );

                                    return (
                                      translation && (
                                        <div key={lang} className="w-100">
                                          <InputLabel
                                            label={
                                              lang === "uz-Latn-UZ"
                                                ? t("uz")
                                                : lang === "uz-Cyrl-UZ"
                                                ? t("cryl")
                                                : t(lang)
                                            }
                                            inputProps={{
                                              value: translation.text,
                                              readOnly: true,
                                            }}
                                          />
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            )
                          ))
                      )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ModerProductView;
