import React, { useState } from "react";
import useHookForm from "../../../../hooks/useHookForm";
import { useQuery } from "@tanstack/react-query";
import { getNotificationTemplateQuery } from "../../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Controller } from "react-hook-form";
import createSchema from "../../../../helpers/createSchema";
import Select from "react-select";
import { Input } from "../../../../Components/atoms/input";
import { toast, ToastContainer } from "react-toastify";
import { postNotifications } from "../../../../api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const values = {
  templateId: null,
  arguments: {},
};
const schema = createSchema({
  templateId: "select",
});

const NotificationsCreate = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    setError,
    
  } = useHookForm(values, schema);
  const [arrgumentsInput, setArrgumentsInput] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    ...getNotificationTemplateQuery(),
  });
  console.log(arrgumentsInput);

  const Submit = async () => {
  setLoading(true);
  let hasError = false; 

  const argument = arrgumentsInput.map((_, index) => {
    const key = watch(`arguments[${index}].key`) || undefined;
    const value = watch(`arguments[${index}].value`) || undefined;

    if (key?.length && !value?.length) {
      setError(`arguments[${index}].value`, {
        type: "manual",
        message: t("Value_is_required_when_Key_is_filled"),
      });
      hasError = true;
    }
    if (value?.length && !key?.length) {
        setError(`arguments[${index}].key`, {
          type: "manual",
          message: t("key_is_required_when_value_is_filled"),
        });
        hasError = true;
      }
    return { [key]: value };
  })

  if (hasError) {
    setLoading(false);
    return; 
  }
    const response = {
      templateId: watch("templateId")?.value,
      arguments: argument.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    };
    try {
      await postNotifications(response);
    } catch (error) {
      toast.error(error);
    }finally {
        setLoading(false);
    }
  };
  console.log(errors);

  const AddInput = () => {
    setArrgumentsInput((prev) => [...prev, {}]);
  };
  const handleDelete = (index) => {
    const updatedAttributes = [...arrgumentsInput];
    updatedAttributes[index] = false;
    updatedAttributes.splice(index, 1);
    setArrgumentsInput(updatedAttributes);
    setValue(`arguments[${index}].key`, "");
    setValue(`arguments[${index}].value`, "");
  };

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Card>
        <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <i
                        className="ri-arrow-left-line fs-3 cursor-pointer"
                        onClick={() => navigate(-1)}
                      ></i>
                      <h5
                        className="card-title flex-grow-1 mb-0"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        {t("add_notification")}
                      </h5>
                    </div>
                    </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(Submit)} className="d-flex flex-column gap-2">
            <Row>
              <Col >
                <Label>{t("Template_name")}</Label>
                <Controller
                  control={control}
                  name="templateId"
                  render={({ field }) => (
                    <Select
                      {...field}
                      className="w-100"
                      isLoading={isLoading}
                      loadingMessage={() => t("loading")}
                      options={data?.item.map((item) => ({
                        value: item.id,
                        label: item.title
                      }))}
                    />
                  )}
                />
                {errors.templateId && (
                  <div className="text-danger">
                    {t(errors.templateId.message)}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
  <Col>
   
    {arrgumentsInput.reduce((rows, _, index) => {
      if (index % 2 === 0) rows.push(arrgumentsInput.slice(index, index + 2));
      return rows;
    }, []).map((row, rowIndex) => (
      <div key={rowIndex} className="mb-3">
        <div className="d-flex gap-3 align-items-center">
          {row.map((_, colIndex) => {
            const inputIndex = rowIndex * 2 + colIndex;
            return (
              <div key={inputIndex} className="w-50">
              <div className="d-flex justify-content-end">
              <Button
                  type="button"
                  onClick={() => handleDelete(inputIndex)}
                  color="danger"
                  className="mt-2"
                >
                  {t("delete")}
                </Button>
              </div>
                <Input
                  control={control}
                  label={t("key")}
                  name={`arguments[${inputIndex}].key`}
                  inputProps={{
                    className: "w-100",
                  }}
                />
                   {errors.arguments && errors.arguments[inputIndex]?.key && (
                    <div className="text-danger">
                        {t(errors.arguments[inputIndex]?.key.message)}
                    </div>
                )}
                 <Input
                   control={control}
                   label={t("value")}
                   name={`arguments[${inputIndex}].value`}
                   inputProps={{
                     className: "w-100",
        
                   }}
            
                 />
                {errors.arguments && errors.arguments[inputIndex]?.value && (
                    <div className="text-danger">
                        {t(errors.arguments[inputIndex]?.value?.message)}
                    </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    ))}
  </Col>
</Row>

            <div className="d-flex justify-content-end gap-3">
              <Button type="button" onClick={AddInput}>
               {t("add_argument")}
              </Button>
              <Button disabled={loading} type="submit">{loading ? <Spinner size={"sm"} /> : t("save")}</Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default NotificationsCreate;
