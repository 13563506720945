import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getReserveByIdQuery } from "../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  Form,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { prettify } from "../../../helpers/price-formater";
import { useTranslation } from "react-i18next";
import Textfield from "../ui/text-field";
import ModalConfirmation from "../ui/modal";
import { postInvoice, ReserveSubOrdersByIdPut } from "../../../api";
import { Controller } from "react-hook-form";
import { Input } from "../../../Components/atoms/input";
import * as Yup from "yup";
import Select from "react-select";
import useHookForm from "../../../hooks/useHookForm";
import { toast, ToastContainer } from "react-toastify";
import { config } from "../../../utils/config";
const form = {
  state: 0,
  reason: "",
};

const ReserveSingle = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    // reason: Yup.string()
    // .nullable()
    // .when('state', {
    //   is: 4,
    //   then: Yup.string().required(t("required_field_error")),
    //   otherwise: Yup.string(),
    // }),
  });

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useHookForm(form, schema);
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState({});
  const clients = useQueryClient();
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const { data, isLoading } = useQuery({
    ...getReserveByIdQuery(id),
  });

  const toggleModal = (index) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [index]: !prevOpen[index],
    }));
  };

  const toggleInvoiceModal = () => {
    setInvoiceModal(!invoiceModal);
  };

  const statusLabel = (state) => {
    switch (state) {
      case 0:
        return t("reserve_created");
      case 1:
        return t("reserve_underway");
      case 2:
        return t("reserve_sent");
      case 3:
        return t("reserve_complated");
      case 4:
        return t("reserve_cancelled");
      default:
        return t("reserve_refund");
    }
  };

  const orderStatus = (status) => {
    switch (status) {
      case 0:
        return t("created");
      case 1:
        return t("rejected");
      case 2:
        return t("accepted");
      case 3:
        return t("HasContract");
      case 4:
        return t("ContractClosed");
      case 5:
        return t("ContractCanceled");
      case 9:
        return t("Deleted");
      case 10:
        return t("processing");
      case 11:
        return t("delevering");
      case 12:
        return t("finished");
      case 13:
        return t("reserval");
    }
  };
  const calculateOrderTotalPrice = (order) => {
    let total = 0;
    order?.subOrders?.forEach((subOrder) => {
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
    });
    return total;
  };

  const calculateSubOrderTotalPrices = (order) => {
    return order?.subOrders?.map((subOrder) => {
      let total = 0;
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
      return total;
    });
  };

  const totalPrice = calculateOrderTotalPrice(data);
  const subOrderTotalPrice = calculateSubOrderTotalPrices(data);
  const invoiceSubmit = async (res) => {
    setInvoiceLoading(true)
    const datas = {
      orderId: +id,
    };

    try {
   const response =   await postInvoice(datas);
      clients.invalidateQueries({ queryKey: ["reserve-id"] });
      clients.invalidateQueries({ queryKey: ["reserve"] });
      if(response) {
        setInvoiceModal(false)
      }
    } catch (error) {
      toast(error.data?.error?.errorMessage || error.data.message, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setInvoiceLoading(false)
    }
  };
  const onSubmit = async (res, subOrderId, index) => {
    setLoading((prev) => ({ ...prev, [subOrderId]: true }));
    const state = res.state[index]?.value;
    const reason = res.state[index]?.value === 4 ? res.reason[index] : "";
    const datas = {
      state: state,
      reason: reason,
    };

    try {
      await ReserveSubOrdersByIdPut(id, subOrderId, datas);

      toast(t("Заказ успешно изменен"), {
        type: "success",
        theme: "colored",
        position: "top-center",
      });
      clients.invalidateQueries({ queryKey: ["reserve-id"] });
      clients.invalidateQueries({ queryKey: ["reserve"] });
    } catch (error) {
      toast(error.data?.error?.errorMessage || error.data.message, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setLoading((prev) => ({ ...prev, [subOrderId]: false }));
    }
  };
  useEffect(() => {
    if (data) {
      const defaultValues = {
        state: data.subOrders.map((item) => ({
          value: item.state,
          label:
            item.state === 0
              ? t("reserve_created")
              : item.state === 1
              ? t("reserve_underway")
              : item.state === 2
              ? t("reserve_sent")
              : item.state === 3
              ? t("reserve_complated")
              : item.state === 4
              ? t("reserve_cancelled")
              : item.state === 5
              ? t("reserve_refund")
              : item?.state === 6
              ? t("reserve_processed")
              : t("reserve_delivered"),
        })),
        reason: data.subOrders.map((item) => item.reason || ""),
      };

      reset(defaultValues);
    }
  }, [data, reset]);
const currency = config.currency();
  return (
    <div
      className="page-content"
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <ToastContainer position="top-center" />
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner size="lg" />
        </div>
      ) : (
        <div>
          <div className="d-flex justify-content-between  align-items-center">
            <div className="d-flex align-items-start gap-3 mb-2">
              <i
                className="ri-arrow-left-line fs-3 cursor-pointer"
                onClick={() => navigate(-1)}
              ></i>
              <h2>
                {t("reserve")} № {data?.id}
              </h2>
            </div>
            <div className="d-flex gap-3 align-items-center">
              <span
                className={`${
                  data?.status === 0 ? `text-info` : `text-success`
                } fs-5`}
              >
                {orderStatus(data?.status)}
              </span>
              <Button
                className={
                  data?.status === 0
                    ? "btn-dark"
                    : data?.status === 3
                    ? "btn-info"
                    : "btn-success"
                }
                disabled={data?.status === 0}
                onClick={() => setInvoiceModal(!invoiceModal)}
              >
                {t(data?.status === 3 ? "see_invoice" : "add_invoice")}
              </Button>
            </div>
          </div>

          <Col xs="12" lg="5" className="d-flex justify-content-center w-100">
            <Card
              style={{
                width: "100%",
                position: "sticky",
                top: "85px",
                zIndex: 999,
                borderRadius: "1rem",
              }}
            >
              <CardHeader
                style={{
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
              >
                <h3>{t("order_information")}</h3>
              </CardHeader>
              <CardBody
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "1rem",
                }}
              >
                {[
                  {
                    name: "phone_number",
                    source: data?.phoneNumber ? "+" + data?.phoneNumber : null,
                  },
                  { name: "client_full_name", source: data?.fullName },
                  {
                    name: "address2",
                    source: data?.deliveryType === 1 ? null : data?.address,
                  },
                  {
                    name: "region",
                    source: data?.deliveryType === 1 ? null : data?.regionName,
                  },
                  {
                    name: "district",
                    source:
                      data?.deliveryType === 1 ? null : data?.destrictName,
                  },
                  { name: "total_price", source: prettify(totalPrice) + " " + t(currency === "aed" ? "AED" : currency)},
                  {
                    name: "delivery_status",
                    source:
                      data?.deliveryType === 0 ? t("delivery") : t("pickup"),
                  },
                  {
                    name: "created_date",
                    source: data?.createdDate
                      ? moment(data?.createdDate).format("DD.MM.YYYY")
                      : null,
                  },
                  { name: "Comments", source: data?.comment },
                ]
                  .sort((a, b) => (b.source ? -1 : 1))
                  .map((item, index) => (
                    <Textfield
                      key={index}
                      name={item.name}
                      source={item.source}
                    />
                  ))}
              </CardBody>
            </Card>
          </Col>
          <Row className="justify-content-between">
            <Col
              xs="12"
              lg="5"
              className="mb-3 d-flex justify-content-between w-100 flex-column"
            >
              {data?.subOrders?.map((item, i) => (
                <div key={i} className="d-flex justify-content-between gap-5">
                  <Card className="mb-3 w-75" style={{ borderRadius: "1rem" }}>
                    <CardHeader
                      className="d-flex align-items-start justify-content-between w-100 m-0"
                      style={{
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                      }}
                    >
                      <div className="d-flex flex-column gap-1">
                        <strong>{item?.organizationName}</strong>
                        <strong>+{item?.organizationPhoneNumber}</strong>
                      </div>

                      <div className="d-flex flex-column align-items-end">
                        <div className="d-flex gap-1 align-items-center">
                          <strong>{t("subOrderTotalPrice")}:</strong>
                          <div>{prettify(subOrderTotalPrice[i]) + " " + t(currency === "aed" ? "AED" : currency)}</div>
                        </div>

                        <div className="d-flex gap-1 align-items-center">
                          <strong>{t("status")}:</strong>
                          <div>{statusLabel(item.state)}</div>
                        </div>
                        <Button
                          hidden={item?.state !== 4}
                          className="p-0 m-0"
                          color="link"
                          onClick={() => toggleModal(i)}
                        >
                          {t("read_the_reason")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="d-flex gap-5 flex-column">
                      {item?.items?.map((child, index) => (
                        <div
                          key={index}
                          className="d-flex gap-5 justify-content-between"
                        >
                          <div className="d-flex gap-3 align-items-start">
                            <img
                              style={{
                                maxWidth: "100px",
                                width: "100px",
                                maxHeight: "100px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              src={child.variation?.files?.sort((a, b) => a.order - b.order)?.[0]?.url}

                              alt={child.variation?.files?.sort((a, b) => a.order - b.order)?.[0]?.id}
                            />
                            <div className="d-flex flex-column gap-1">
                              <div style={{ width: "250px" }}>
                                {child.variation?.product?.name}
                              </div>
                              <strong>
                                {prettify(
                                  child?.variation?.prices?.find(
                                    (price) => price.type === "Price"
                                  )?.value + " " + t(currency === "aed" ? "AED" : currency)
                                )}
                              </strong>

                              <div>x{child?.count}</div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </CardBody>

                    <ModalConfirmation
                      title={item?.organizationName}
                      isOpen={open[i]}
                      toggleConfirmation={() => toggleModal(i)}
                    >
                      <p>{item?.reason}</p>
                    </ModalConfirmation>
                  </Card>
                  <Card
                    className="w-25"
                    style={{
                      borderRadius: "1rem",
                      maxHeight:
                        watch(`state[${i}]`)?.value === 4 ? "310px" : "150px",
                      position: "sticky",
                      top: "85px",
                    }}
                  >
                    <CardBody>
                      <Form
                        onSubmit={handleSubmit((res) =>
                          onSubmit(res, item.id, i)
                        )}
                      >
                        <div className="mb-3 w-100">
                          <Label for="positionId">{t("status")}</Label>
                          <Controller
                            name={`state[${i}]`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={[
                                  { value: 0, label: t("reserve_created") },
                                  { value: 1, label: t("reserve_underway") },
                                  { value: 2, label: t("reserve_sent") },
                                  { value: 3, label: t("reserve_complated") },
                                  { value: 4, label: t("reserve_cancelled") },
                                  { value: 5, label: t("reserve_refund") },
                                  { value: 6, label: t("reserve_processed") },
                                  { value: 7, label: t("reserve_delivered") },
                                ]}
                                placeholder="Select Position"
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption);
                                  setValue(`state[${i}]`, selectedOption);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-3 w-100">
                          {watch(`state[${i}]`)?.value === 4 && (
                            <Input
                              name={`reason[${i}]`}
                              control={control}
                              errors={errors}
                              
                              label={t("reason")}
                              inputProps={{
                                placeholder: t("reason"),
                                type: "textarea",
                                rows: 5,
                                style: { maxHeight: "120px" }
                              }}
                            />
                          )}
                        </div>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="btn btn-primary d-flex align-items-center gap-2  "
                            type="submit"
                            disabled={loading[item.id] || watch(`state[${i}]`)?.value === 4 && !watch(`reason[${i}]`)?.length}
                          >
                            {loading[item.id] ? (
                              <Spinner size="sm" className="me-2">
                                Loading...
                              </Spinner>
                            ) : null}
                            {t("save")}
                            <i className="ri-check-line"></i>
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )}
      <ModalConfirmation
        title={data?.status === 2 ? t("add_invoice"): t("invoice_information")}
        isOpen={invoiceModal}
        toggleConfirmation={() => toggleInvoiceModal()}
        size={"lg"}
      >
     
       <div style={{display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
        <div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("orderId")}: </span> <span>#{data?.id}</span>
        </div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("created_date")}: </span> <span>{moment(data?.createdDate).format("DD.MM.YYYY")}</span>
        </div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("region")}: </span> <span>{data?.regionName}</span>
        </div>
        </div>
        <div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("client_full_name")}: </span> <span>{data?.fullName}</span>
        </div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("phone_number")}: </span> <span>{data?.phoneNumber ? "+" + data?.phoneNumber : null}</span>
        </div>
        <div className="d-flex gap-2">
          <span style={{fontWeight: "bold", fontSize: "0.9rem"}}>{t("district")}: </span> <span>{data?.destrictName}</span>
        </div>
        </div>
       </div>
       <Table bordered>
        <thead>
          <tr>
            <th>{t("product_name")}</th>
            <th>{t("quantity")}</th>
            <th>{t("Price")}</th>
          </tr>
        </thead>
        <tbody>
          {data?.subOrders?.map((item) => (
            item?.items.map((child) => (
              <tr key={child}>
                <td>
                  {child?.variation?.product?.name}
                </td>
                <td>
                  {child?.count}
                </td>
                <td>
                  {prettify(child?.variation?.prices.find(
                    (price) => price.type === "Price"
                  ).value * child?.count)  + " " + t(currency === "aed" ? "AED" : currency)}
                </td>
              </tr>
            ))
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th >
              {t("total_price")}
            </th>
            <td></td>
            <td >{prettify(totalPrice) + " " + t(currency === "aed" ? "AED" : currency)}</td>
          </tr>
        </tfoot>
       </Table>
        <div
          className={
            data?.status === 3 ? "d-none" : `d-flex justify-content-end gap-2`
          }
        >
          <Button onClick={() => setInvoiceModal(!invoiceModal)}>
            {t("cancel")}
          </Button>
          <Button disabled={invoiceLoading} onClick={invoiceSubmit}>{invoiceLoading ? <Spinner size={"sm"}/> : t("yes")}</Button>
        </div>
      </ModalConfirmation>
    </div>
  );
};

export default ReserveSingle;
