import React from "react";
import { config } from "../../../../utils/config";
import { Button, Card, CardBody, CardHeader, Col, Form, Label, Row } from "reactstrap";
import useHookForm from "../../../../hooks/useHookForm";
import createSchema from "../../../../helpers/createSchema";
import { postNotificationTemplate } from "../../../../api";
import { Input } from "../../../../Components/atoms/input";
import { Textarea } from "../../../../Components/atoms/textarea";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const values = {
  group: null,
  type: null,
  title: {
    ru: "",
    "uz-Latn-UZ": "",
    "uz-Cyrl-UZ": "",
    en: "",
  },
  body: {
    ru: "",
    "uz-Latn-UZ": "",
    "uz-Cyrl-UZ": "",
    en: "",
  },
  requiredArguments: null,
};
const schema = createSchema({
  title: "title",
  body: "body",
  type: "select"
});
const TemplateCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useHookForm(values, schema);
  let languages = ["ru", "uz-Latn-UZ", "uz-Cyrl-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  const Submit = async (data) => {
    const title = Object.entries(data.title).map(([languageCode, text]) => ({
        languageCode,
        text: text || "",
      }));
    
      const body = Object.entries(data.body).map(([languageCode, text]) => ({
        languageCode,
        text: text || "",
      }));
    const response = {
      group: null,
      type: watch("type")?.value,
      title,
      body,
    };
    try {
      await postNotificationTemplate(response);
    } catch (error) {
      console.log(error);
    }
  };

const types = [
    {value: 1, label: "firebase"},
    {value: 2, label: "sms"},
    {value: 4, label: "email"},
    {value: 8, label: "telegram"}
]
  return (
    <div className="page-content">
      <Card>
        <CardHeader className="text-center">
        <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <i
                        className="ri-arrow-left-line fs-3 cursor-pointer"
                        onClick={() => navigate(-1)}
                      ></i>
                      <h5
                        className="card-title flex-grow-1 mb-0"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        {t("create_notification_template")}
                      </h5>
                    </div>
                    </div>
        </CardHeader>
        <CardBody>
        <Form onSubmit={handleSubmit(Submit)}>
            <div className="d-flex flex-column gap-4">
              {languages.map((langCode) => (
                <Row key={langCode} className="mb-4">
                  <Col md={6}>
                   
                    <Input
                      control={control}
                      errors={errors}
                      label={t(`name_${langCode}`)}
                      name={`title.${langCode}`}
                      inputProps={{
                        placeholder: t("Enter the title"),
                      }}
                      className="form-control-lg"
                    />
                    {errors.title && errors.title[langCode] && (
                      <div className="text-danger">
                        {t(errors.title[langCode].message)}
                      </div>
                    )}
                  </Col>

                  <Col md={6}>
                  
                    <Textarea
                    label={t(`description_${langCode}`)}
                      control={control}
                      errors={errors}
                      name={`body.${langCode}`}
                      inputProps={{
                        placeholder: t("Enter a description"),
                        rows: 1,
                      }}
                      className="form-control-lg"
                    />
                    {errors.body && errors.body[langCode] && (
                      <div className="text-danger">
                        {t(errors.body[langCode].message)}
                      </div>
                    )}
                  </Col>
                </Row>
              ))}

              <Col style={{width: "49%"}}>
              <Label >{t("type")}</Label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={types?.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      placeholder={t("type")}
                      className="react-select-lg"
                    />
                  )}
                />
                {errors.type && (
                  <div className="text-danger">{t(errors.type.message)}</div>
                )}
              </Col>

              <div className="d-flex justify-content-end mt-4">
                <Button type="submit" color="primary" className="px-4 py-2">
                  {t("save")}
                </Button>
              </div>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
  
};

export default TemplateCreate;
