import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useQuery } from "@tanstack/react-query";
import { getReserveQuery } from "../../../queries";
import classnames from "classnames";
import AccordionReserve from "../ui/accordion-reserve";
import { toast, ToastContainer } from "react-toastify";
import ModalConfirmation from "../ui/modal";

const values = {
  page: 1,
  size: 20,
  phoneNumber: null,
  name: null,
  subOrderState: null
};

const Reserve = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [open, setOpen] = useState(false);
  const [filterValues, setFilterValues] = useState({
    phoneNumber: "",
    name: "",
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const { data, isFetched } = useQuery({
    ...getReserveQuery(formValues),
    onError: (err) => {
      if (err.response && err.response.status === 500) {
        toast.error(
          t("An error occurred on the server. Please try again later.")
        );
      }
    },
  });
  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, subOrderState: value }));
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      size: newSize,
      page: 1,
    }));
  };

  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (e) => {
    e.preventDefault();

    setFormValues((prevState) => ({
      ...prevState,
      phoneNumber: filterValues.phoneNumber,
      name: filterValues.name,
      page: 1,
    }));
    setIsFilterApplied(true)
    toggleModal();
  };

  const handleReset = () => {
    setFilterValues({
      phoneNumber: "",
      name: "",
    });
    setIsFilterApplied(false)

    
  };

  useEffect(() => {
    const hasFilters = filterValues.name || filterValues.phoneNumber;
    setIsFilterApplied(!!hasFilters); 
  }, [formValues]);

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("reserves")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
           
                     {[null, 0, 1, 2, 3, 4, 5].map((state, index) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: formValues?.subOrderState === state },
                              "fw-semibold"
                            )}
                            onClick={() => handleNavClick(state)}
                            href="#"
                          >
                            {t(
                              [
                                "all_reserve",
                                "reserve_created",
                                "reserve_underway",
                                "reserve_sent",
                                "reserve_complated",
                                "reserve_cancelled",
                                "reserve_refund",
                              ][index]
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                   <Button
                  onClick={toggleModal}
                  color={isFilterApplied ? "primary": "light"}
                  className="d-flex align-items-center gap-1"
                >
                  <i className="ri-equalizer-fill"></i>
                  {t("filter")}
                </Button>
                  </div>
                  {isFetched ? (
                    <div className="mt-2">
                      <AccordionReserve
                        data={data?.items}
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                      />

                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={formValues?.size}
                        onChange={handlePageSizeChange}
                        className="w-auto"
                      >
                         {[20, 50, 100].map((size) => (
                          <option key={size} value={size}>
                            {t(String(size))}
                          </option>
                        ))}
                      </Input>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <ModalConfirmation
            isOpen={open}
            toggleConfirmation={toggleModal}
            title={t("filter")}
          >
            <Form onSubmit={handleSubmitFilter} className="d-flex flex-column gap-2">
           <div>
           <Label>{t("phone_number")}</Label>
              <Input
                type="tel"
                name="phoneNumber"
                value={filterValues.phoneNumber}
                onChange={handleFilterChange}
                placeholder={t("phone_number")}
              />
           </div>
             <div>
             <Label>{t("client_full_name")}</Label>
              <Input
                type="text"
                name="name"
                value={filterValues.name}
                onChange={handleFilterChange}
                placeholder={t("client_full_name")}
              />
             </div>
              <div className="d-flex justify-content-end gap-3">
              <Button onClick={handleReset} color="light" type="submit">{t("Reset")}</Button>
              <Button disabled={!filterValues.name && !filterValues.phoneNumber} type="submit">{t("save")}</Button>
              </div>
            </Form>
          </ModalConfirmation>
        </Row>
      </Container>
    </div>
  );
};

export default Reserve;
