import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Button, Form, Input, InputGroup, Label } from "reactstrap";

const FilterInputs = ({ setFormValues, fields, type, style, toggleModal }) => {
  const { t } = useTranslation();
  const [parentCategory, setParentCategory] = useState(null);
  const [childCategory, setChildCategory] = useState(null);
  const [grandSonCategory, setGrandSonCategory] = useState(null);
  const parentCategoryRef = useRef(null);

  const [formValues, setFormState] = useState(
    fields?.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );

  const focusParentCategory = () => {
    // const resetValues = fields?.reduce(
    //   (acc, field) => ({ ...acc, [field.name]: "" }),
    //   {}
    // );
    parentCategoryRef.current?.focus();
    setParentCategory(null);
    setChildCategory(null);
    setGrandSonCategory(null);
    // setFormState(resetValues);

    setFormValues((prevState) => ({
      ...prevState,
      // ...resetValues,
      categoryName: null,
      categoryId: null,
      page: 1,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newValue = value.length > 0 ? value : "";

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormValues((prevState) => ({
      ...prevState,
      ...formValues,
      page: 1,
    }));
    toggleModal && toggleModal();
  };
  const handleSelectChange = (name, selectedOption) => {
    const newValue = selectedOption ? selectedOption.value : "";

    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    type === "filter" &&
      setFormValues((prevState) => ({
        ...prevState,
        [name]: newValue,
        page: 1,
      }));
  };
  const handleReset = () => {
    const resetValues = fields?.reduce(
      (acc, field) => ({ ...acc, [field.name]: "" }),
      {}
    );
    setParentCategory(null);
    setChildCategory(null);
    setGrandSonCategory(null);
    setFormState(resetValues);

    setFormValues((prevState) => ({
      ...prevState,
      ...resetValues,
      page: 1,
    }));
    toggleModal && toggleModal();
  };
  const dynamicStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px",
  };

  const inputStyle = (columns) => ({
    width: `calc(${100 / columns}% - 32px)`,
  });
  console.log(childCategory, grandSonCategory);

  const columns = style || 3;
  if (type === "form") {
    return (
      <Form
        onSubmit={handleSubmit}
        className="w-100 h-100 d-flex flex-column justify-content-between"
        style={{ height: "100vh" }}
      >
        <div className="d-flex gap-3" style={dynamicStyle}>
          {fields?.map((field) => (
            <div key={field.name} style={inputStyle(columns)}>
              <Label htmlFor={field.name}>{t(field.label)}</Label>

              {field.type === "select" ? (
                <div className="d-flex align-items-center gap-3">
                  <Select
                    isMulti={field.isMulti}
                    name={field.name}
                    id={field.name}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "200px",
                      }),
                    }}
                    value={
                      field.options?.find(
                        (option) => option.value === formValues[field.name]
                      ) || formValues[field.name]
                    }
                    onChange={(selectedOption) =>
                      handleSelectChange(field.name, selectedOption)
                    }
                    options={field.options}
                  />
                </div>
              ) : field.type === "category" ? (
                <>
                  <div className="d-flex gap-2 flex-wrap">
                    {!parentCategory?.array?.childs?.length &&
                      !childCategory &&
                      !grandSonCategory && (
                        <Select
                          isClearable
                          ref={parentCategoryRef}
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                            }),
                          }}
                          value={parentCategory}
                          options={field.options?.map((item) => ({
                            value: item.name,
                            label: item.name,
                            array: item,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setParentCategory(selectedOption);
                            setChildCategory(null);
                            setGrandSonCategory(null);
                            if (
                              !selectedOption?.array?.childs ||
                              selectedOption.array.childs.length === 0
                            ) {
                              handleSelectChange(field.name, selectedOption);
                            }
                          }}
                        />
                      )}

                    {parentCategory?.array?.childs &&
                      !childCategory?.array?.childs?.length &&
                      !grandSonCategory && (
                        <Select
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                            }),
                          }}
                          value={childCategory}
                          options={parentCategory.array.childs.map((item) => ({
                            value: item.name,
                            label: item.name,
                            array: item,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setChildCategory(selectedOption);
                            setGrandSonCategory(null);
                            if (
                              !selectedOption?.array?.childs ||
                              selectedOption.array.childs.length === 0
                            ) {
                              handleSelectChange(field.name, selectedOption);
                            }
                          }}
                        />
                      )}
                    <div
                      className="d-flex gap-2 flex-wrap"
                      style={{ position: "relative" }}
                    >
                      {childCategory?.array?.childs && (
                        <Select
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                              position: "relative",
                            }),
                          }}
                          value={grandSonCategory}
                          options={childCategory.array.childs.map((item) => ({
                            value: item.name,
                            label: item.name,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setGrandSonCategory(selectedOption);
                            handleSelectChange(field.name, selectedOption);
                          }}
                        />
                      )}

                      {childCategory?.value ? (
                        <div
                          className="border-0 position-absolute p-0 m-0"
                          style={{ right: "35px", top: "1px" }}
                        >
                          <Button
                            color="none"
                            outline
                            onClick={focusParentCategory}
                          >
                            <i className="bx bx-x fs-4"></i>
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <span>
                      {parentCategory?.label}
                      {childCategory?.label && ` / ${childCategory.label}`}
                    </span>
                  </div>
                </>
              ) : (
                <div className="w-100">
                  <Input
                    type={field.type || "text"}
                    name={field.name}
                    id={field.name}
                    className="form-control"
                    value={formValues[field.name]}
                    onChange={handleInputChange}
                    placeholder={t(field.placeholder)}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end gap-3 mt-3 ">
          <Button onClick={handleReset} color="light">
            {t("reset_all_filters")}
          </Button>
          <Button type="submit">{t("save")}</Button>
        </div>
      </Form>
    );
  } else if (type === "filter") {
    return (
      <Form onSubmit={handleSubmit} className="d-flex align-items-start">
        <InputGroup className="d-flex gap-3 align-items-start">
          {fields?.map((field, index) => (
            <div key={field.name} className="d-flex align-items-start">
              {field.type === "select" ? (
                <div className="d-flex align-items-center gap-3">
                  <Select
                    isClearable
                    isSearchable={true}
                    isMulti={field.isMulti}
                    placeholder={t(field.placeholder)}
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "200px",
                        position: "sticky",
                        top: 0,
                        background: "#fff",
                        zIndex: 34,
                      }),
                    }}
                    key={field.name}
                    name={field.name}
                    id={field.name}
                    value={
                      field.options?.find(
                        (option) => option.value === formValues[field.name]
                      ) || formValues[field.name]
                    }
                    onChange={(selectedOption) =>
                      handleSelectChange(field.name, selectedOption)
                    }
                    options={field.options}
                  />
                </div>
              ) : field.type === "category" ? (
                <div>
                  <div className="d-flex gap-2">
                    {!parentCategory?.array?.childs?.length &&
                      !childCategory &&
                      !grandSonCategory && (
                        <Select
                          isClearable
                          ref={parentCategoryRef}
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                            }),
                          }}
                          value={parentCategory}
                          options={field.options?.map((item) => ({
                            value:
                              field.valueName === "name"
                                ? item.name
                                : field.valueName === "id" && item.id,
                            label: item.name,
                            array: item,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setParentCategory(selectedOption);
                            setChildCategory(null);
                            setGrandSonCategory(null);
                            if (
                              !selectedOption?.array?.childs ||
                              selectedOption.array.childs.length === 0
                            ) {
                              handleSelectChange(field.name, selectedOption);
                            }
                          }}
                        />
                      )}

                    {parentCategory?.array?.childs &&
                      !childCategory?.array?.childs?.length &&
                      !grandSonCategory && (
                        <Select
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                            }),
                          }}
                          value={childCategory}
                          options={parentCategory.array.childs.map((item) => ({
                            value:
                              field.valueName === "name"
                                ? item.name
                                : field.valueName === "id" && item.id,
                            label: item.name,
                            array: item,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setChildCategory(selectedOption);
                            setGrandSonCategory(null);
                            if (
                              !selectedOption?.array?.childs ||
                              selectedOption.array.childs.length === 0
                            ) {
                              handleSelectChange(field.name, selectedOption);
                            }
                          }}
                        />
                      )}

                    <div
                      className="d-flex gap-2 flex-wrap"
                      style={{
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      {childCategory?.array?.childs && (
                        <Select
                          styles={{
                            control: (base) => ({
                              ...base,
                              width: "200px",
                              position: "relative",
                            }),
                          }}
                          value={grandSonCategory}
                          options={childCategory.array.childs.map((item) => ({
                            value:
                              field.valueName === "name"
                                ? item.name
                                : field.valueName === "id" && item.id,
                            label: item.name,
                          }))}
                          placeholder={t(field.placeholder)}
                          onChange={(selectedOption) => {
                            setGrandSonCategory(selectedOption);
                            handleSelectChange(field.name, selectedOption);
                          }}
                        />
                      )}

                      {childCategory?.value && (
                        <div
                          className="border-0 position-absolute p-0 m-0"
                          style={{ right: "35px", top: "1px" }}
                        >
                          <Button
                            color="none"
                            outline
                            onClick={focusParentCategory}
                          >
                            <i className="bx bx-x fs-4"></i>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  <span>
                    {parentCategory?.array?.childs && parentCategory?.label}
                    {childCategory?.label && childCategory?.array?.childs && ` / ${childCategory.label}`}
                  </span>
                </div>
              ) : (
                <div className="d-flex position-relative">
                  <Input
                 
                    type={field.type || "text"}
                    name={field.name}
                    className="form-control"
                    style={{
                      borderTopRightRadius: index === 0 && 0,
                      borderBottomRightRadius: index === 0 && 0,
                      width: field.width
                    }}
                    value={formValues?.[field?.name]}
                    onChange={handleInputChange}
                    placeholder={t(field.placeholder)}
                  />
                  <Button
                    outline
                    color="none"
                    className="border-0 position-absolute"
                    style={{ right: index === 0 ? 35 : 0, top: 2 }}
                    hidden={!formValues[field.name].length}
                    onClick={() => {
                      setFormState((prevState) => ({
                        ...prevState,
                        [field.name]: "",
                      }));
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        [field.name]: "",
                      }));
                    }}
                  >
                    <i className="bx bx-x fs-4"></i>
                  </Button>
                  {index === 0 && (
                    <Button
                      type="submit"
                      style={{
                        borderTopLeftRadius: index === 0 && 0,
                        borderBottomLeftRadius: index === 0 && 0,
                      }}
                      onClick={handleSubmit}
                    >
                      <i className="bx bx-search"></i>
                    </Button>
                  )}
                </div>
              )}
            </div>
          ))}
        </InputGroup>
      </Form>
    );
  } else if (type === "select") {
    return (
      <Form>
        {fields?.map((field) => (
          <Select
            isMulti={field.isMulti || false}
            onKeyDown={handleSubmit}
            key={field.name}
            name={field.name}
            id={field.name}
            value={
              field.options?.find(
                (option) => option.value === formValues[field.name]
              ) || null
            }
            onChange={(selectedOption) =>
              handleSelectChange(field.name, selectedOption)
            }
            options={field.options}
          />
        ))}
      </Form>
    );
  } else {
    return null;
  }
};

export default FilterInputs;
