import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Spinner, Label, Form } from "reactstrap";
import { Input } from "../../../Components/atoms/input";
import { DateInput } from "../../../Components/atoms/date-input";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import * as moment from "moment";
import "cleave.js/dist/addons/cleave-phone.uz";
import {
  editOrganizationContract,
  editOrganizationState,
  createOrganizationContract,
} from "../../../api/organization";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const schema = createSchema({});
const values = {
  agreement1: {
    contractNumber: "",
    organizationId: "",
    saleType: 1,
    contractDate: "",
  },
  agreement2: {
    contractNumber: "",
    organizationId: "",
    saleType: 0,
    contractDate: "",
  },
};
const saleType = {
  1: { value: 0, label: "Рассрочка" },
  2: { value: 1, label: "Компенсация" },
  4: { value: 2, label: "Продажа" },
  8: { value: 3, label: "ООН" },
};
const OrgContractEdit = ({ organization }) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("");
  const { t } = useTranslation();
  const { id } = useParams();
  const QueryClient = useQueryClient();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useHookForm(values, schema);
  
  const productSaleTypes = Object.keys(saleType)
    .map(Number)
    .filter((type) => (organization?.productSaleTypes & type) !== 0)
    .map((type) => saleType[type] || null)
    .filter(Boolean);


 
  const onSubmitContract = handleSubmit(async (res) => {
    setLoading(true);


    const data = { id: parseInt(id), state };

    const agreementPromises = productSaleTypes.map(({ value }) => {
      const agreement = res[`agreement_${value}`];
      const agreementId = res[`agreementId_${value}`];


      if (agreementId) {
        return editOrganizationContract(agreement, agreementId);
      } else if (agreement?.contractNumber) {
        return createOrganizationContract(agreement);
      }
      return Promise.resolve();
    });

    try {
      await Promise.all(agreementPromises);
      await editOrganizationState(data, id);

      QueryClient.invalidateQueries(["user-organizations"]);
      toast("Контракт и статус изменены", {
        type: "success",
        theme: "colored",
        position: "top-center",
        autoClose: 2000,
      });
    } catch (error) {
      toast("Ошибка при изменении данных", {
        type: "error",
        theme: "colored",
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (organization) {
      setState(organization.state);

      const defaultValues = {};
      organization.contracts?.forEach((contract) => {
        const saleTypeInfo =
          saleType[contract.saleType] || saleType[`${contract.saleType}`];



        if (
          contract.saleType === 0 ||
          contract.saleType === 1 ||
          contract.saleType === 2 ||
          contract.saleType === 3
        ) {
          defaultValues[`agreement_${contract.saleType}`] = {
            contractNumber: contract.contractNumber || "",
            organizationId: parseInt(id),
            saleType: contract.saleType,
            contractDate: contract.contractDate
              ? moment(contract.contractDate).format("YYYY.MM.DD")
              : "",
          };
          defaultValues[`agreementId_${contract.saleType}`] = contract.id;
        } else if (saleTypeInfo) {
          defaultValues[`agreement_${saleTypeInfo.value}`] = {
            contractNumber: contract.contractNumber || "",
            organizationId: parseInt(id),
            saleType: saleTypeInfo.value,
            contractDate: contract.contractDate
              ? moment(contract.contractDate).format("YYYY.MM.DD")
              : "",
          };
          defaultValues[`agreementId_${saleTypeInfo.value}`] = contract.id;
        } else {
          console.warn(`⚠️ saleType[${contract.saleType}] qiymati topilmadi!`);
        }
      });

      productSaleTypes.forEach(({ value }) => {
        if (!defaultValues[`agreement_${value}`]) {
          defaultValues[`agreement_${value}`] = {
            contractNumber: "",
            organizationId: parseInt(id),
            saleType: value,
            contractDate: "",
          };
        }
      });

      reset(defaultValues);
    }
  }, [organization, id, reset]);


  document.title = "Organizations-Contract Edit | Taqsim";
  return (
    <Form action="#" className="d-flex flex-column gap-3">
      <Card>
        <Row className="d-flex flex-column align-items-start p-4 gap-4">
          <h4
            className="card-title flex-grow-1 mb-0 text-dark"
            style={{ fontWeight: 600 }}
          >
            {t("agreements")}
          </h4>
          <Row className="w-100 d-flex flex-column gap-3">
            <Col xl={12}>
              {productSaleTypes.map(({ value, label }) => (
                <div
                  key={value}
                  className="d-flex justify-content-between gap-5"
                >
                  <div className="w-100">
                    <Input
                      errors={errors}
                      name={`agreement_${value}.contractNumber`}
                      label={`${label}`}
                      control={control}
                    />
                  </div>
                  <div className="w-100">
                    <DateInput
                      type="date"
                      className="form-control mb-3"
                      id={`contractDate_${value}`}
                      name={`agreement_${value}.contractDate`}
                      errors={errors}
                      label={`${label}`}
                      control={control}
                    />
                  </div>
                </div>
              ))}
            </Col>

            <Col xl={5} >
              <Label>{t("status")}</Label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                onChange={(e) => setState(parseInt(e.target.value))}
                value={state}
              >
                <option value={0}>{"Создан"}</option>
                <option value={1}>{"Активный"}</option>
                <option value={2}>{"Не активный"}</option>
              </select>
            </Col>
          </Row>
          <Col xl={3}>
            <Button
              color="success"
              className="btn btn-success w-100"
              type="submit"
              onClick={onSubmitContract}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                  Loading...{" "}
                </Spinner>
              ) : null}
              {t("save")}
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default OrgContractEdit;
