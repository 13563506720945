import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { getAttributesQuery, getCategoriesQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { deleteAttribute } from "../../../api";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";


const values = { page: 1, size: 20 };

const AttributesList = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const QueryClient = useQueryClient();
  const [selectedId, setSelectedId] = useState(null);
  
  const { data, isFetched } = useQuery({
    ...getAttributesQuery(formValues),
    
  });
  const { mutate } = useMutation({
    mutationFn: (id) => deleteAttribute(id),
  });
  const toggleModal = (id) => {
    setSelectedId((prev) => (prev === id ? null : id));
    setModal((prev) => !prev);
  };
  const {data: category} = useQuery({
    ...getCategoriesQuery({page: 1, size: 2147483647})
  })
  const handleDelete = (id) => {
    setLoading(true);
    setModal(true)
    mutate(id, {
      onSuccess: () => {
        toast.success(t("Successfully"));
        QueryClient.invalidateQueries({ queryKey: ["attributes"] });
        setSelectedId(null);
        setModal(false)
      },
      onError: (error) => {
        alert(error.data?.error?.errorMessage);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("category_name"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("weight"),
        accessor: "weight",
        filterable: false,
      },
      {
        Header: t("hasFilter"),
        accessor: "hasFilter",
        filterable: false,
        Cell: (cell) => {
          return cell.value ? <>{t("yes")}</> : <>{t("no")}</>;
        },
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,
        Cell: (cell) => {
          return cell.value ? <>{t("yes")}</> : <>{t("no")}</>;
        },
      },
      {
        Header: t("type"),
        accessor: "type",
        filterable: false,
      },
      {
        accessor: "button",
        Cell: (cell) => {
          return (
            <>
              <btn
                className="ri-delete-bin-5-line fs-4 p-1"
                onClick={() => toggleModal(cell.row.original.id)}
                style={{ cursor: "pointer" }}
              />
              <Modal
                isOpen={selectedId === cell.row.original.id}
                toggle={() => toggleModal(cell.row.original.id)}
                modalClassName="zoomIn"
                centered
                size="sm"
              >
                <ModalHeader>{t("delete_confirmation")}</ModalHeader>
                <ModalBody>
                  <i
                    className="ri-alert-fill text-warning d-flex justify-content-center"
                    style={{ fontSize: "50px" }}
                  ></i>
                  <p className="text-center">{t("text_delete")}</p>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => toggleModal(cell.row.original.id)}>
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={() => handleDelete(cell.row.original.id)}
                    className="btn btn-danger"
                  >
                    {loading ? <Spinner size="sm" className="me-2" /> : null}{" "}
                    {t("delete")}
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          );
        },
      },
      {
        accessor: "Action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/attributes/edit/${cell.row.original.id}`}
                   
                  className="ri-edit-line fs-4 p-1 fs-16 cursor-pointer"
                />
              </li>
            </ul>
          );
        },
      },
    ],
    [selectedId, loading, t]
  );
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  document.title = "Attributes | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title={t("attributes_group")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-end gap-3 w-100 mb-3">
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[     
                      { name: "categoryId", type: "category", placeholder: "category_name", options: category, valueName: "id" },                  
                    ]}
                  />
                
                </div>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("attributes_group")}</h5>
                    <Link
                      to={"/attributes/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                          pagination={{
                            currentPage: formValues?.page,
                            totalPages: data?.pagination?.TotalPages,
                            onChange: handlePageChange,
                          }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"attributes"}
                        pageKey={"page"}
                        sizeKey={"size"}
                      />
                      {/* <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input> */}
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttributesList;
