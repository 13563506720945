import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
// import * as moment from "moment";
// import classnames from "classnames";
// import debounce from "lodash/debounce";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getOrganizationsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { getOrganizations } from "../../../api";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";

const schema = createSchema({});
const values = {
  pageIndex: 1,
  pageSize: 20,
  sortBy: "id",
  desc: true,
  states: 0,
};

const ModerOrganizations = () => {
  const [allDataLoading, setAllDataLoading] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [formValues, setFormValues] = useState(values)
  const [exportModal, setExportModal] = useState(false);
  const { t } = useTranslation();

  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getOrganizationsQuery(formValues),
  });

  // Fetch All Data for Excel export
  const getAllData = () => {
    setAllDataLoading(true);
    getOrganizations({
      ...watch(),
      pageSize: data?.pagination?.TotalCount,
    })
      .then((res) => {
        setDataForDownload(res);
        setExportModal(true);
      })
      .finally(() => setAllDataLoading(false));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organizationName",
        filterable: false,
      },
      {
        Header: t("region"),
        accessor: "region.name",
        filterable: false,
      },
      {
        Header: t("owner_full_name"),
        accessor: "ownerFullName",
        filterable: false,
      },
      {
        Header: t("contract_type"),
        accessor: "contractType",
        filterable: false,
        Cell: (cell) => {
          return <>{t(`contract_type${cell.value}`)}</>;
        },
      },
      {
        Header: t("states"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return <div>{cell.value === 0 ? t("created") : t("")}</div>;
        },
      },
      {
        accessor: "action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/organizations/edit/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-edit-line fs-4 p-1 fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlePageChange = (pageIndex) => {
    setFormValues((prevState) => ({ ...prevState, pageIndex }));
  };


  return (
    <div className="page-content">
      <ExportCSVModal
        show={exportModal}
        onCloseClick={() => setExportModal(false)}
        data={dataForDownload}
        dataName={"Organizations"}
      />
      <Container fluid>
        <BreadCrumb title={t("moder_organizations")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("organizations")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                    <FilterInputs type={"filter"} setFormValues={setFormValues} fields={[{name: "phoneNumber", placeholder: "phone_number", type: "number"}]} />
                      <Button
                        type="button"
                        className="btn"
                        color="primary"
                        onClick={getAllData}
                        disabled={allDataLoading}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t(allDataLoading ? "downloading" : "export")}
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                           pagination={{
                            currentPage: formValues?.pageIndex,
                            totalPages: data?.pagination?.TotalPages,
                            onChange: handlePageChange,
                          }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                        <PageSize formValues={formValues} setFormValues={setFormValues} tableKey={"moderation-organizations"} sizeKey={"pageSize"} pageKey={"pageIndex"}/>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ModerOrganizations;
