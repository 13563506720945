/* eslint-disable comma-dangle */
import * as yup from "yup";
import { config } from "./config";

const schemaFieldTypes = {
  required: yup
    .string()
    .strict()
    .trim("spaces_error")
    .required("required_field_error"),
  default: yup
    .string()
    .strict()
    .trim("spaces_error")
    .required("required_field_error"),
  name: yup
    .string()
    .strict()
    .trim()
    .required("required_field_error"),
    // .matches(/^[a-zA-Zа-яА-Я\s']+$/, "spaces_error"),
  auth_password: yup
    .string()
    .required("required_field_error")
    .min(8, "min_8_chars_error"),
  retypePassword: yup
    .string()
    .test("passwords-match", "password_must_match", function test(value) {
      return this.parent.newPass === value;
    }),
  retypePassword2: yup
    .string()
    .test("passwords-match", "password_must_match", function test(value) {
      return this.parent.password === value;
    }),
  phone: yup
    .string()
    .required("required_field_error")
    .matches(
      /^998([- ])?(90|91|93|94|95|98|99|33|97|88|71|77)([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
      "invalid_phone_number"
    ),
  phoneWithout998: yup
    .string()
    .required("required_field_error")
    .matches(
      /^(90|91|93|94|95|98|99|33|97|88|71|77)([- ])?(\d{3})([- ])?(\d{2})([- ])?(\d{2})$/,
      "invalid_phone_number"
    ),
  select: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("required_field_error"),
  multiselect: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .min(1, "required_field_error"),
  email: yup.string().required("required_field_error").email("invalid_email"),
  birthday: yup
    .date()
    .required("required_field_error")
    .min(new Date("1970-01-01"), "invalid_birthday")
    .max(new Date(), "invalid_birthday"),
  number: yup
    .number()
    .required("required_field_error")
    .typeError("type_must_number")
    .min(0, "min_max_weight")
    .max(9, "min_max_weight"),
    weight: yup.number().required("required_field_error").typeError("type_must_number").min(1, "min_max_weight").max(100, "min_max_weight"),
  numberOrder: yup
    .number()
    .required("required_field_error")
    .typeError("type_must_number"),
    title: yup.object().shape({
      ru: yup.string().required("required_field_error"),
      "uz-Latn-UZ": yup.string().required("required_field_error"),
      "uz-Cyrl-UZ": yup.string().required("required_field_error"),
      en: yup.string().when([], {
        is: () => config.SITENAME === "food",
        then: yup.string().required("required_field_error"),
        otherwise: yup.string().nullable(),
      }),
    }),
    body: yup.object().shape({
      ru: yup.string().required("required_field_error"),
      "uz-Latn-UZ": yup.string().required("required_field_error"),
      "uz-Cyrl-UZ": yup.string().required("required_field_error"),
      en: yup.string().when([], {
        is: () => config.SITENAME === "food",
        then: yup.string().required("required_field_error"),
        otherwise: yup.string().nullable(),
      }),
    }),
};

export default schemaFieldTypes;
