import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Input as InputFile,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createAttributes, editAttributes } from "../../../api";
import { getCategoriesQuery, getAttributeQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import {
  findParentId,
  dataTypeIndex,
} from "../../../helpers/findParentCategoryId";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { Controller } from "react-hook-form";
import RadioButtonInput from "../ui/radio-button";
import { config } from "../../../utils/config";
const isFood = config.SITENAME === "food"
const schema = createSchema({
  nameru: "name",
  "nameuz-Latn-UZ": "name",
  ...(isFood
    ? { nameen: "name" }
    : { "nameuz-Cyrl-UZ": "name" }),
  descriptionru: "name",
  "descriptionuz-Latn-UZ": "name",
  ...(isFood
    ? { descriptionen: "name" }
    : { "descriptionuz-Cyrl-UZ": "name" }),
  weight: "weight",
});

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
    en: ""
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
    en: ""
  },
  hasFilter: "",
  isRequired: "",
  isVisible: "",
  weight: "",
  dataType: "",
  categoryId: "",
  isValueTranslated: "",
  filterId: "",
};

const AttributeCategory = () => {
  
  const [onChange, setOnChange] = useState("");
  const [filtersData, setFiltersData] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isValueTranslated, setIsValueTranslated] = useState(false);
  const [filterDataType, setFilterDataType] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const QueryClient = useQueryClient();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
    setValue,
  } = useHookForm(values, schema);

  // FETCHING
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { data: categories, isLoading: categoriesLoading } = useQuery({
    ...getCategoriesQuery(),
  });
  const { data: attribute , isLoading} = useQuery({
    ...getAttributeQuery(id),
    enabled: id !== undefined,
  });
 
 const FiltersDataFetch = async () => {
   const categoryId =
   watch("childCategory")?.value || watch("parentCategory")?.value;
   if (categoryId) {
      fetch(
        `
  ${baseUrl}v1/filters?categoryId=${categoryId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setFiltersData(res.item);
          res.item.filter(
            (filter) =>
              filter.id === watch("filterId") &&
              (setValue("filterId", { value: filter.id, label: filter.name }),
              setValue("dataType", {
                value: filter.dataType,
                label: filter.dataType,
              }))
          );
          
        });
    }
  };
  const filterType = filtersData.find(
    (item) =>
      (item.filterType === "MultipleSelect" || item.filterType === "SingleSelect") &&
      item.id === watch("filterId")?.value
  );


  const onSubmit = handleSubmit((res) => {
    if (!watch("parentCategory")) {
      return setError("parentCategory", {
        type: "manual",
        message: t("required"),
      });
    }
    if (hasFilter) {
      if (!watch("filterId")) {
        return setError("filterId", {
          type: "manual",
          message: t("required"),
        });
      }
    } else {
      if (!watch("dataType")) {
        return setError("dataType", {
          type: "manual",
          message: t("required"),
        });
      }
    }


    const names = languages.map((languageCode) => ({
      languageCode,
      text: res[`name${languageCode}`],
    }));

    const descriptions = languages.map((languageCode) => ({
      languageCode,
      text: res[`description${languageCode}`],
    }));
   
    setLoading(true);
    const datas = {
      name: names,
      categoryId:
        res?.grandSonCategory?.value ||
        res?.childCategory?.value ||
        res?.parentCategory?.value,
      description: descriptions,
      dataType: res.dataType?.value || filterDataType,
      weight: parseInt(res.weight),
      isRequired,
      hasFilter,
      isVisible,
      isValueTranslated,
      ...(hasFilter && { filterId: res.filterId.value }),
      ...(id && { id: parseInt(id) }),
    };
    id
      ? editAttributes(datas)
          .then(() => {
            setLoading(false);
            navigate("/attributes");
            QueryClient.invalidateQueries(["attributes"]);
            toast("Аттрибут изменен", {
              type: "success",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast("Что-то пошло не так", {
              type: "error",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
      : createAttributes(datas)
          .then(() => {
            setLoading(false);
            navigate("/attributes");
            QueryClient.invalidateQueries(["attributes"]);
            toast("Аттрибут добавлен", {
              type: "success",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast("Что-то пошло не так", {
              type: "error",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          });
  });
  useEffect(() => {
    setValue("childCategory", {});
    setValue("grandSonCategory", {});
    setValue("filterId", {});
  }, [onChange]);
  
  useEffect(() => {
    if (attribute && categories) {
      
      attribute.name.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      attribute.description.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      const parentData = findParentId(categories, attribute?.categoryId)?.parent || {};
           const childData = findParentId(categories, attribute?.categoryId)?.child || {};
           const grandSonData = findParentId(categories, attribute?.categoryId)?.grandSon || {};
       
           setValue("parentCategory", parentData);
           setValue("childCategory", childData);
           setValue("grandSonCategory", grandSonData);
      setHasFilter(attribute.hasFilter);
      setIsRequired(attribute.isRequired);
      setIsVisible(attribute.isVisible);
      setIsValueTranslated(attribute.isValueTranslated);
      setValue("dataType", {
        value: dataTypeIndex(attribute.dataType),
        label: dataTypeIndex(attribute.dataType),
      });
      setValue("weight", attribute.weight);
      setValue("filterId", attribute.filterId);
      FiltersDataFetch();
    }
  }, [attribute, categories, setValue]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  useEffect(() => {
    if(hasFilter) {
      setValue("dataType", "")
    }
    else {
      setValue("filterId", "")
    }
  }, [hasFilter, setValue])
  return (
    <div className="page-content">
     {isLoading || categoriesLoading ? (
      <div className="d-flex justify-content-center">
        <Spinner size={"lg"} />
      </div>
     ) : (
      <Container fluid>
      <ToastContainer />
      <Row>
        <Col className="d-flex justify-content-center">
          <Card className="w-75">
            <CardHeader>
              <div className="d-flex align-items-center gap-3">
              <i
            className="ri-arrow-left-line fs-3 cursor-pointer"
            onClick={() =>  window.opener ? window.close() : navigate(-1)}
          ></i>
                <h5
                  className="card-title flex-grow-1 mb-0"
                  style={{ fontSize: "20px", fontWeight: 700 }}
                >
                  {id ? t("edit_attribute") : t("add_attribute")}
                </h5>
              </div>
            </CardHeader>
            <CardBody>
              <Form action="#" className="d-flex flex-column gap-3">
                <Label>{t("change_category")}</Label>
                <Controller
                  name="parentCategory"
                  control={control}
                  className="bg-white"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={categories?.map((item) => ({
                        value: item.id,
                        label: item.name,
                        array: item,
                      }))}
                      placeholder={t("Select parent category")}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        setOnChange(selectedOption.value);
                      }}
                    />
                  )}
                />
                {errors.parentCategory && (
                  <span className="text-danger">
                    {errors.parentCategory.message}
                  </span>
                )}
                {watch("parentCategory")?.array && watch("parentCategory")?.array?.childs !== null && (
                  <Controller
                    name="childCategory"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={watch("parentCategory")?.array?.childs?.map(
                          (item) => ({
                            value: item.id,
                            label: item.name,
                            array: item,
                          })
                        )}
                        placeholder={t("Select child category")}
                      />
                    )}
                  />
                )}
                {watch("childCategory")?.array &&
                  watch("childCategory")?.array?.childs !== null && (
                    <Controller
                      name="grandSonCategory"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={watch("childCategory")?.array?.childs?.map(
                            (item) => ({
                              value: item.id,
                              label: item.name,
                            })
                          )}
                          placeholder={t("Select child category")}
                        />
                      )}
                    />
                  )}
                {/* FILTERS */}
                <RadioButtonInput
                  label={"filters"}
                  state={hasFilter}
                  setState={setHasFilter}
                  id={2}
                />

                {hasFilter ? (
                  <>
                    <Label>{t("change_filter")}</Label>
                    <Controller
                      name="filterId"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={filtersData?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder={t("Select FILTER")}
                          onChange={(selectedOption) => {
                            const type = filtersData?.filter(
                              (item) => item.id === selectedOption.value
                            );
                            field.onChange(selectedOption);
                            setFilterDataType(type[0].dataType);
                          }}
                          onFocus={FiltersDataFetch}
                        />
                      )}
                    />
                    {errors.filterId && (
                      <span
                        style={{ fontSize: "0.71rem" }}
                        className="text-danger"
                      >
                        {t(errors.filterId.message)}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <Label>{t("dataType")}</Label>
                    <Controller
                      name="dataType"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={[
                            { value: "Text", label: "Text" },
                            { value: "Integer", label: "Integer" },
                            {
                              value: "Float",
                              label: "Float",
                            },
                            { value: "Date", label: "Date" },
                            { value: "Bool", label: "Bool" },
                            { value: "Char", label: "Char" },
                          ]}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                          }}
                        />
                      )}
                    />
                    {errors.dataType && (
                      <span
                        style={{ fontSize: "0.71rem" }}
                        className="text-danger"
                      >
                        {t(errors.dataType.message)}
                      </span>
                    )}
                  </>
                )}
                <Input
                  control={control}
                  errors={errors}
                  name="weight"
                  label="weight"
                  isInteger
                  maxLength={1}
                />
                <RadioButtonInput
                  label={"isValueTranslated"}
                  state={isValueTranslated}
                  setState={setIsValueTranslated}
                  disabled={watch("dataType").value === "Integer" || filterType}
                  dataType={watch("dataType").value === "Integer" || filterType} 
                  id={4}
                />
                <RadioButtonInput
                  label={"required_field_error"}
                  state={isRequired}
                  setState={setIsRequired}
                  id={1}
                />
                <RadioButtonInput
                  label={"isVisible"}
                  state={isVisible}
                  setState={setIsVisible}
                  id={3}
                />
                {/* NAME & DESCRITPION TRANSLATE */}
               
<div className="d-flex flex-column gap-3">
                  {languages.map((langCode) => (
                    <div key={langCode}>
                     
                      <Input
                      label={t(`name_${langCode}`)}
                        control={control}
                        errors={errors}
                        name={`name${langCode}`}
                        inputProps={{
                          placeholder: t("enter_name"),
                        }}
                      />
                      {errors.name && errors.name[langCode] && (
                        <div className="text-danger">
                          {t(errors.name[langCode].message)}
                        </div>
                      )}
                    </div>
                  ))}

               
                </div>
        
<div className="d-flex flex-column gap-3">
                  {languages.map((langCode) => (
                    <div key={langCode}>
                     
                      <Input
                      label={t(`description_${langCode}`)}
                        control={control}
                        errors={errors}
                        name={`description${langCode}`}
                        inputProps={{
                          placeholder: t("enter_name"),
                        }}
                      />
                      {errors.name && errors.name[langCode] && (
                        <div className="text-danger">
                          {t(errors.name[langCode].message)}
                        </div>
                      )}
                    </div>
                  ))}

               
                </div>
                <Button
                  color="success"
                  className="btn btn-success w-100"
                  type="submit"
                  onClick={onSubmit}
                >
                  {loading ? (
                    <Spinner size="sm" className="me-2">
                      {" "}
                      Loading...{" "}
                    </Spinner>
                  ) : null}
                  {t("save")}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
     )}
    </div>
  );
};

export default AttributeCategory;
