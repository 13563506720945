import request from "../utils/axios";

export const getOrders = (params) => {
  return request({ method: "GET", url: "/orders", params });
};

export const getOrder = (id) => {
  return request({ method: "GET", url: `/orders/${id}` });
};

export const getReserve = (params) => {
  return request({ method: "GET", url: "/orders/m", params });
};

export const getReserveById = (id) => {
  return request({ method: "GET", url: `/orders/m/${id}` });
};
export const ReserveSubOrdersByIdPut = (id, subOrderId, data) => {
  return request({
    method: "PUT",
    url: `/orders/m/${id}/suborders/${subOrderId}`,
    data,
  });
};

export const getContracts = (params) => {
  return request({ method: "GET", url: "/contracts", params });
};

export const getOrganizations = (params) => {
  return request({ method: "GET", url: "/organizations", params });
};
export const getOrganization = (id) => {
  return request({ method: "GET", url: `/organizations/${id}` });
};

export const getClients = (params) => {
  return request({ method: "GET", url: "/clients", params });
};

export const getClient = (id) => {
  return request({ method: "GET", url: `/clients/${id}` });
};

export const getProducts = (params) => {
  return request({ method: "GET", url: "/v1/products", params });
};

export const getProduct = (id) => {
  return request({ method: "GET", url: `/v1/products/${id}` });
};

export const getProductsCard = (productId) => {
  return request({
    method: "GET",
    url: `/v1/products/variations/${productId}`,
  });
};

export const getModerations = (params) => {
  return request({ method: "GET", url: "/v1/moderations", params });
};

export const getModeration = (moderId) => {
  return request({ method: "GET", url: `/v1/moderations/id?id=${moderId}` });
};

export const getCategories = (params) => {
  return request({ method: "GET", url: "/v1/categories", params });
};
export const getCategory = (id) => {
  return request({ method: "GET", url: `/v1/categories/${id}` });
};
export const deleteCategories = (id) => {
  return request({ method: "DELETE", url: `/v1/categories/${id}` });
};

export const getFilters = (params) => {
  return request({ method: "GET", url: "/v1/filters", params });
};

export const getFiltersById = (id) => {
  return request({ method: "GET", url: `/v1/filters/${id}`});
};

export const deleteFilters = (id) => {
  return request({ method: "DELETE", url: `/v1/filters/${id}` });
};

export const editFilter = (data) => {
  return request({method: "PUT", url: "/v1/Filters", data})
}

export const getAttributes = (params) => {
  return request({ method: "GET", url: "/v1/attributes", params });
};
export const getAttribute = (id) => {
  return request({ method: "GET", url: `/v1/attributes/${id}` });
};
export const deleteAttribute = (id) => {
  return request({ method: "DELETE", url: `/v1/attributes/${id}` });
};
export const getOrgFormalType = () => {
  return request({ method: "GET", url: "/organizations/formal-types" });
};
export const getRegions = () => {
  return request({ method: "GET", url: "/regions" });
};
export const getReviews = (params) => {
  return request({ method: "GET", url: "/Reviews", params });
};
export const getTabs = (params) => {
  return request({ method: "GET", url: "/Tabs", params });
};
export const getTab = (id) => {
  return request({ method: "GET", url: `/Tabs/${id}` });
};
export const deleteTab = (id) => {
  return request({ method: "DELETE", url: `/Tabs/${id}` });
};
export const deleteTabItem = (parentId, id) => {
  return request({ method: "DELETE", url: `/Tabs/${parentId}/items/${id}` });
};
export const deleteTabVarationId = (id, variationId) => {
  return request({ method: "DELETE", url: `/Tabs/${id}/products/m/${variationId}` });
}
// CRUD *****************************************

export const createCategories = (data) => {
  return request({ method: "POST", url: "/v1/categories", data });
};
export const editCategories = (data) => {
  return request({ method: "PUT", url: "/v1/categories", data });
};
export const createFilters = (data) => {
  return request({ method: "POST", url: "/v1/filters", data });
};
export const createAttributes = (data) => {
  return request({ method: "POST", url: "/v1/attributes", data });
};
export const editAttributes = (data) => {
  return request({ method: "PUT", url: "/v1/attributes", data });
};
export const createModerationProduct = (data) => {
  return request({ method: "POST", url: "/v1/moderations", data });
};
export const publishReviews = (id) => {
  return request({ method: "POST", url: `/Reviews/${id}/publish` });
};
export const rejectReviews = (id) => {
  return request({ method: "POST", url: `/Reviews/${id}/reject` });
};
export const addService = (data) => {
  return request({ method: "POST", url: "/Tabs", data });
};
export const editService = (id, data) => {
  return request({ method: "PUT", url: `/Tabs/${id}`, data });
};
export const addServiceItem = (parentId, data) => {
  return request({ method: "POST", url: `/Tabs/${parentId}/items`, data });
};
export const editServiceItem = (parentId, id, data) => {
  return request({ method: "PUT", url: `/Tabs/${parentId}/items/${id}`, data });
};
export const addTabProduct = (id, data) => {
  return request({ method: "POST", url: `/Tabs/${id}/products/m`, data });
};

export const getTabProduct = (id, params) => {
  return request({ method: "GET", url: `/Tabs/${id}/products/m`, params });
};


export const getPositions = (params) => {
  return request({method: "GET", url: "/positions", params})
}
export const getPositionPermissions = (params) => {
  return request({method: "GET", url: "/positions/permissions", params})
}

export const editPositions = (id,data) => {
  return request({method: "PUT", url: `/positions/${id}`,data})
}
export const createBrand = (data, method) => {
  return request({method: method, url: "/v1/Brands", data})
}
export const BrandGetById = (id, data) => {
  return request({method: "GET", url: `/v1/Brands/${id}`, data})
}

export const getBrand = (params) => {
  return request({method: "GET", url: "/v1/Brands", params})
}

export const deleteBrand = (id) => {
  return request({method: "DELETE", url: `/v1/Brands/${id}`})
}

export const getFiles = (id,data) => {
  return request({method: "GET", url: `/v1/files/${id}`,data})
}
export const uploadPhoto = (data) => {
  return request({
    method: "POST",
    url: `/v1/files`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getProfile = (params) => {
  return request({method: "GET", url: "/profile/info", params})
}

//notifications 

export const postNotificationTemplate = (data) => {
  return request({method: "POST", url: "/notificationTemplates", data})
}
export const getNotificationTemplate = (params) => {
  return request({method: "GET", url: "/notificationTemplates", params})
}

export const getNotifications = (params) => {
  return request({method: "GET", url: "/notifications", params})
 }

 export const postNotifications = (data) => {
  return request({method: "POST", url: "/notifications", data})
 }

 export const postInvoice = (data) => {
  return request({method: "POST", url: "/invoices", data})
 }