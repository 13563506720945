import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import Select from "react-select";
import { getNotificationTemplateQuery } from "../../../../queries";
import PageSize from "../../ui/pageSize";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../../../Components/Common/TableContainer";
import Loader from "../../../../Components/Common/Loader";
const values = {
  page: 1,
  size: 20,
  moderationStatus: null,
};

const NotificationTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(values);
  const { data, isFetched } = useQuery({
    ...getNotificationTemplateQuery(formValues),
  });

  // Column
  const columns = useMemo(
    () => [
    
      {
        Header: t("Title"),
        accessor: "title",
        filterable: false,
      },
      {
        Header: t("product_category"),
        accessor: "category.name",
        filterable: false,
      },
    ],
    []
  );
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  document.title = "Products | Taqsim";
  const moderationStatusOption = [
    { value: null, label: "all" },
    { value: 0, label: "On_moderate_products" },
    { value: 1, label: "Checked_products" },
    { value: 3, label: "Reject_products" },
  ];
  const stateOption = [
    { value: 1, label: "Product_active" },
    { value: 0, label: "Product_deactive" },
  ];
  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, moderationStatus: value }));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("notification_templates")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="align-items-center d-flex justify-content-between">
                <Row>
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      {t("notification_templates")}
                    </h5>
                  </div>
                </Row>
                <Button
                  onClick={() => navigate("/notifications/template/create")}
                >
                  {t("add")}
                </Button>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        data={data.item || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"confirm-products"}
                        pageKey={"page"}
                        sizeKey={"size"}
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationTemplate;

// const css = {
//   steteStyle: {
//     color: "white",
//     borderRadius: "10px",
//     fontSize: "0.7rem",
//     padding: "2px 4px",
//   },
// };
