import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
  Input as TextInput,
  Spinner,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getProductsQuery, getTabProductQuery, getTabQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
// import { deleteTabItem } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import { prettify } from "../../../helpers/price-formater";
import { config } from "../../../utils/config";
import { deleteTabVarationId } from "../../../api";
import ModalConfirmation from "../ui/modal";

const schema = {};
const values = {};

const TabDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);
  const QueryClient = useQueryClient();
const toggleModal = (id) => {
  setModal(!modal);
setSelectId(id);
if(loading) {
  setModal(true)
}
}
  const { data: tab } = useQuery({
    ...getTabQuery(id),
  });
  const { data: products, isLoading } = useQuery({
    ...getTabProductQuery(id),
  });

  const {mutate} = useMutation({
    mutationFn: (variationId) => deleteTabVarationId(id, variationId),
  })

  const deleteTabItem = (variationId) => {
    setLoading(true);
    setModal(true);
    mutate(variationId, {
      onSuccess: () => {
        toast.success(t("Successfully"), {autoClose: 2000});
        QueryClient.invalidateQueries({ queryKey: ["tab"] });
        QueryClient.invalidateQueries({ queryKey:  ["productTab"] });
        setModal(false);
      },
      onError: () => {
        toast.error(t("Failed"));
      },onSettled: () => {
        setLoading(false);
      }
    });
  }
  useEffect(() => {
    if (tab) {
      tab.names.map((item) => setValue(`name${item.languageCode}`, item.text));
      setValue("state", serviceState(tab.state));
      setValue("type", serviceType(tab.type));
      setValue("order", tab.order);
    }
  }, [tab]);

  document.title = "Service Details | Taqsim";
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer  />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="ri-arrow-left-line fs-3 cursor-pointer"
                    onClick={() => navigate(-1)}
                  ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {t("about_tab")}
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                <i className="ri-add-line fs-4 p-1"></i>
                  <Button onClick={() => navigate(`/tab/${id}/add/products`)} color="none" className="border-0 p-0">{t("add_element")}</Button>
                </div>
              </CardHeader>
              <CardBody className="d-flex justify-content-between flex-wrap gap-4">
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="type"
                    label="tab_type"
                    inputProps={{
                      readOnly: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="state"
                    label="tab_state"
                    inputProps={{
                      readOnly: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="order"
                    label="order"
                    inputProps={{
                      readOnly: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                </Row>
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <div className="d-flex flex-column gap-2">
                    {languages.map((langCode) => (
                      <div key={langCode}>
                        <Input
                          label={t(`name_${langCode}`)}
                          control={control}
                          errors={errors}
                          name={`name${langCode}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                {products?.length > 0 && <Label>{t("products")}</Label>}
              </CardHeader>
              <CardBody>
                <Row className="d-flex justify-content-between gap-3 w-100">
                  {
                    isLoading ? (
                      <div className="d-flex justify-content-center w-100">
                        <Spinner size="lg" />
                      </div>
                    ) :
                    <>
                      {products?.map(
                        (item) =>
                          item !== null && (
                            <>
                              <div
                                className="border form-control col-4 d-flex flex-column gap-1"
                                style={{ marginLeft: "0.8rem", width: "45%" }}
                                id="img2"
                                key={item.id}
                              >
                                <Label>{t("product_name")}</Label>
                                <TextInput value={item.name} readOnly />

                                <Label>{t("product_number")}</Label>
                                <TextInput
                                  value={item.productNumber}
                                  readOnly
                                />

                                <Label>{t("origin_price")}</Label>
                                <TextInput
                                  value={prettify(
                                    item.variations[0].originPrice
                                  )}
                                  readOnly
                                />
                                <div>
                                <Button
                                className="mt-2"
                                color="danger"
                                onClick={() =>
                                  toggleModal(item?.variations?.[0]?.id)
                                }
                              >
                                {t("delete")}
                              </Button>
                                </div>
                              </div>
                            </>
                          )
                      )}
                      <ModalConfirmation
                        title={t("delete_confirmations")}
                        isOpen={modal}
                        toggleConfirmation={toggleModal}
                      >
                        <div className="d-flex justify-content-evenly">
                          <Button
                          disabled={loading}
                            color="success"
                            className="w-25"
                            onClick={() => toggleModal()}
                          >
                            {t("no")}
                          </Button>
                          <Button
                          disabled={loading}
                            color="danger"
                            className="w-25"
                            onClick={() => {
                              const variationId = products?.filter((item) => item.variations?.[0].id === selectId)
                             return (
                              deleteTabItem(
                                variationId?.[0]?.variations?.[0]?.id
                              ) 
                             )
                            }}
                          >
                            {loading ? <Spinner size={"sm"} /> : t("yes")}
                          </Button>
                        </div>
                      </ModalConfirmation>
                    </>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TabDetail;
