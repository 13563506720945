import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import {
  getCategoriesQuery,
  getOrganizationsQuery,
  getProductsQuery,
} from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { prettify } from "../../../helpers/price-formater";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";
import ModalConfirmation from "../ui/modal";

const values = {
  page: 1,
  size: 20,
  moderationStatus: 0,
  productName: null,
};

const ModerationProducts = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [open, setOpen] = useState(false);
  const { data, isFetched } = useQuery({
    ...getProductsQuery(formValues),
  });

 const { data: category } = useQuery({
    ...getCategoriesQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });
  const { data: organization } = useQuery({
    ...getOrganizationsQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("product_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("products_category"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("compensation_price"),
        accessor: "variations[0].compensationPrice",
        filterable: false,
        Cell: (cell) => {
          return <div>{prettify(cell.value)}</div>;
        },
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === false ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === false ? t("invisible") : t("visible")}
            </div>
          );
        },
      },
      {
        Header: t("state"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === 0 ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === 0 ? t("inactive") : t("active")}
            </div>
          );
        },
      },
      {
        accessor: "Action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/moder-products/${cell.row.original.variations?.[0].id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );
  


  //push
  document.title = "Products | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
          <div className="d-flex justify-content-end w-100 mb-3">
          <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
                      { name: "productName", placeholder: "product_name", width: "400px" },
                  
                   
                    
                    
                     
                    ]}
                  />
          </div>
            <Card id="orderList">
              <CardHeader className="card-header border-0 d-flex justify-content-between">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("products")}</h5>
                  </div>
                  <div className="d-flex justify-content-end gap-3 w-100">
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
             
                      
                      {
                        name: "organizationId",
                        type: "select",
                        label: "organization_name",
                        placeholder: "organization_name",
                        options: organization?.map((item) => ({
                          value: item.id,
                          label: item.organizationName,
                        })),
                      },
                   
                      { name: "categoryName", type: "category", placeholder: "category_name", options: category, valueName: "name" },
                    
                     
                    ]}
                  />
                
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />

                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"moderation-products"}
                        pageKey={"page"}
                        sizeKey={"size"}
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalConfirmation
          isOpen={open}
          toggleConfirmation={toggleModal}
          title={t("filter")}
        >
          <div>
            <FilterInputs
              type="form"
              toggleModal={toggleModal}
              setFormValues={setFormValues}
              fields={[
                {
                  name: "productName",
                  placeholder: "product_name",
                  type: "text",
                  label: "product_name",
                },
                {
                  name: "categoryName",
                  placeholder: "Category",
                  type: "text",
                  label: "category_name",
                },
              ]}
            />
          </div>
        </ModalConfirmation>
      </Container>
    </div>
  );
};

export default ModerationProducts;

const css = {
  steteStyle: {
    color: "white",
    borderRadius: "10px",
    fontSize: "0.7rem",
    padding: "2px 4px",
  },
};
