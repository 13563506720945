import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import ModalConfirmation from "../../ui/modal";
import { deleteTabVarationId } from "../../../../api";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { prettify } from "../../../../helpers/price-formater";

const ServiceProducts = ({
  products,
  isLoading,
  id,
  languages,
  data,
  watch,
}) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const QueryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate } = useMutation({
    mutationFn: (variationId) => deleteTabVarationId(id, variationId),
  });
  const [selectId, setSelectId] = useState(null);

  const toggleModal = (id) => {
    setModal(!modal);
    setSelectId(id);
    if (loading) {
      setModal(true);
    }
  };
  const deleteTabItem = (variationId) => {
    setLoading(true);
    setModal(true);
    mutate(variationId, {
      onSuccess: () => {
        toast.success(t("Successfully"), { autoClose: 2000 });
        QueryClient.invalidateQueries({ queryKey: ["tab"] });
        QueryClient.invalidateQueries({ queryKey: ["productTab"] });
        setModal(false);
      },
      onError: () => {
        toast.error(t("Failed"));
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };
  
  return (
    <div>
      <Card hidden={!products?.length && !data.colors?.length}>
        <CardHeader>
          {products?.length > 0 && <Label>{t("products")}</Label>}
        </CardHeader>
        <CardBody>
          <div className="d-flex w-100 gap-5 p-3">
            {languages.map((item) => {
              const value = watch(`name${item}`) || "";

              return (
                <div key={item} style={{
                  background: `linear-gradient(45deg, ${data.colors?.join(", ")})`,
                  color: "white",
                  padding: "5px 12px",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  display: "inline-block"
                }}>
                  {value}
                </div>
                
              );
            })}
          </div>
          <Row className="d-flex justify-content-between gap-3 w-100">
            {isLoading ? (
              <div className="d-flex justify-content-center w-100">
                <Spinner size="lg" />
              </div>
            ) : (
              <>
                {products?.map(
                  (item) =>
                    item !== null && (
                      <>
                        <div
                          className="border form-control col-4 d-flex flex-column gap-1"
                          style={{ marginLeft: "0.8rem", width: "45%" }}
                          id="img2"
                          key={item.id}
                        >
                          <Label>{t("product_name")}</Label>
                          <Input value={item.name} readOnly />

                          <Label>{t("product_number")}</Label>
                          <Input value={item.productNumber} readOnly />

                          <Label>{t("origin_price")}</Label>
                          <Input
                            value={prettify(item.variations[0].originPrice)}
                            readOnly
                          />
                          <div>
                            <Button
                              className="mt-2"
                              color="danger"
                              onClick={() =>
                                toggleModal(item?.variations?.[0]?.id)
                              }
                            >
                              {t("delete")}
                            </Button>
                          </div>
                        </div>
                      </>
                    )
                )}
                <ModalConfirmation
                  title={t("delete_confirmations")}
                  isOpen={modal}
                  toggleConfirmation={toggleModal}
                >
                  <div className="d-flex justify-content-evenly">
                    <Button
                      disabled={loading}
                      color="success"
                      className="w-25"
                      onClick={() => toggleModal()}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      disabled={loading}
                      color="danger"
                      className="w-25"
                      onClick={() => {
                        const variationId = products?.filter(
                          (item) => item.variations?.[0].id === selectId
                        );
                        return deleteTabItem(
                          variationId?.[0]?.variations?.[0]?.id
                        );
                      }}
                    >
                      {loading ? <Spinner size={"sm"} /> : t("yes")}
                    </Button>
                  </div>
                </ModalConfirmation>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default ServiceProducts;
