import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Button, Row, Col, Input } from "reactstrap";
import { prettify } from "../../../helpers/price-formater";
import { Link, useNavigate } from "react-router-dom";
import * as moment from "moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { config } from "../../../utils/config";
const AccordionReserve = ({ data, pagination }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const calculateOrderTotalPrice = (order) => {
    let total = 0;
    order?.subOrders?.forEach((subOrder) => {
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
    });
    return total;
  };
  const currency = config.currency();
  const statusLabel = (state) => {
    switch (state) {
      case 0:
        return t("reserve_created");
      case 1:
        return t("reserve_undrway");
      case 2:
        return t("reserve_sent");
      case 3:
        return t("reserve_complated");
      case 4:
        return t("reserve_cancelled");
      default:
        return t("reserve_refund");
    }
  };

  const paymentType = (type) => {
    switch (type) {
      case 0:
        return t("Terminal");
      case 1:
        return t("Card");
      case 2:
        return t("Cash");
      case 3:
        return t("Wallet");
    }
  };

  return (
    <div>
      <div
        style={{
          overflowX: "auto",
          maxHeight: `calc(100vh - 250px)`,
          overflowY: "auto",
        }}
      >
        <Table bordered style={{ minWidth: "1500px" }}>
          <thead
            style={{
              position: "sticky",
              top: -5,
              background: "#fff",
              zIndex: 999,
            }}
          >
            <tr>
              <th>{t("created_date")}</th>
              <th>{t("order_information")}</th>
              <th>{t("Price")}</th>
              <th>{t("payment_method")}</th>
              <th>{t("status")}</th>
              <th>{t("action")}</th>
            </tr>
          </thead>
          {/* <tbody>
            {data?.length ? (
              data?.map((order, orderIndex) => {
                

                return (
                  <React.Fragment key={`order-${orderIndex}`}>
                    {order?.subOrders?.map((subOrder, subOrderIndex) => (
                        subOrder?.items?.map((item, itemIndex) => (
                      <tr key={`subOrder-${subOrderIndex}`}>
                        {subOrderIndex === 0 && (
                          <td rowSpan={order.subOrders.length}>
                            <div
                              className="d-flex flex-column gap-1 align-items-center justify-content-center"
                              style={{
                                position: "sticky",
                                top: 50,
                                zIndex: 99,
                              }}
                            >
                              <span>
                                {moment(order?.createdDate)
                                  .add(5, "hours")
                                  .format("DD.MM.YYYY")}
                              </span>
                              <span>
                                {moment(order?.createdDate)
                                  .add(5, "hours")
                                  .format("HH:mm:ss")}
                              </span>
                              <br />
                              <span>+{order?.phoneNumber}</span>
                              <span>{order?.fullName}</span>
                            </div>
                          </td>
                        )}
                        <td
                          className={`p-0 ${
                            subOrderIndex === subOrder.items.length - 1
                              ? "border-none"
                              : "border-bottom"
                          }`}
                        >
                          <div className="d-flex flex-column gap-2">
                              <div
                                key={`item-${itemIndex}`}
                                className={`d-flex gap-2 flex-column p-3 ${
                                  subOrderIndex === subOrder.items.length - 1 ||
                                  subOrderIndex === order.subOrders.length - 1
                                    ? "border-none"
                                    : "border-bottom"
                                }`}
                              >
                                {itemIndex === 0 && subOrderIndex === 0 && (
                                  <strong style={{ fontSize: "16px" }}>
                                    <Link to={`/reserve/${order.id}`}>
                                      #{order?.id}
                                    </Link>
                                  </strong>
                                )}
                                <div className="d-flex gap-4 align-items-start w-100">
                                  <PhotoProvider>
                                    <div
                                      style={{
                                        backgroundColor: "rgb(239, 239, 239)",
                                        minHeight: "80px",
                                        width: "80px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "14px",
                                      }}
                                    >
                                      {item?.variation?.files
                                        .sort((a, b) => a.order - b.order)
                                        ?.map(
                                          (image, index) => 
                                            index === 0 &&
                                           (
                                              <PhotoView
                                                key={index}
                                                src={image?.url}
                                              >
                                                <img
                                                  style={{
                                                    cursor: "pointer",
                                                    borderRadius: "6px",
                                                    width: "100%",
                                                    maxHeight: "80px",
                                                    objectFit: "contain",
                                                  }}
                                                  src={image?.url}
                                                  alt={image?.id}
                                                />
                                              </PhotoView>
                                            )
                                        )}
                                    </div>
                                  </PhotoProvider>
                                  <div
                                    className="d-flex flex-column justify-content-between"
                                    style={{ height: "80px" }}
                                  >
                                    <Link
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: 600,
                                        overflow: "hidden",
                                        whiteSpace: "wrap",
                                        maxWidth: "100%",
                                      }}
                                      to={`/products/${item?.variation?.product?.id}`}
                                    >
                                      {item?.variation?.product?.name}
                                    </Link>
                                    <div
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        textAlign: "left",
                                      }}
                                    >
                                      {t("quantity")}: {item?.count}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </td>

                        <td>
                          {prettify(
                            item?.variation?.prices?.find(
                              (price) => price.type === "Price"
                            )?.value * item?.count
                          ) +
                            " " +
                            t(currency === "aed" ? "AED" : currency)}
                        </td>

                        <td>{paymentType(order?.paymentType)}</td>
                        <td>{statusLabel(order?.status)}</td>
                        {itemIndex === 0 && (
                          <td rowSpan={subOrder.items.length}>
                            <div
                              style={{
                                position: "sticky",
                                top: 50,
                                zIndex: 99,
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <Button
                                style={{ background: "#D5D5D5" }}
                                color="none"
                                size="sm"
                                outline
                                onClick={() =>
                                  navigate(
                                    `/reserve/${order.id}`
                                  )
                                }
                              >
                                {t("read_more")}
                              </Button>
                            </div>
                          </td>
                        )}
                      </tr>
                              ))
                    ))}
                  </React.Fragment>
                );
              })
             
              
            ) : (
              <tr className="text-center">
                <td colSpan={10}>{t("no_data")}</td>
              </tr>
            )}
          </tbody> */}
          <tbody>
  {data?.length ? (
    data?.map((order, orderIndex) => {
      const totalItems = order.subOrders.reduce((acc, subOrder) => acc + subOrder.items.length, 0);

      return (
        <React.Fragment key={`order-${orderIndex}`}>
          {order?.subOrders?.map((subOrder, subOrderIndex) =>
            subOrder?.items?.map((item, itemIndex) => (
              <tr key={`subOrder-${subOrderIndex}-item-${itemIndex}`}>
                {itemIndex === 0 && subOrderIndex === 0 && (
                  <td rowSpan={totalItems}>
                    <div
                      className="d-flex flex-column gap-1 align-items-center justify-content-center"
                      style={{
                        position: "sticky",
                        top: 50,
                        zIndex: 99,
                      }}
                    >
                      <span>{moment(order?.createdDate).add(5, "hours").format("DD.MM.YYYY")}</span>
                      <span>{moment(order?.createdDate).add(5, "hours").format("HH:mm:ss")}</span>
                      <br />
                      <span>+{order?.phoneNumber}</span>
                      <span>{order?.fullName}</span>
                    </div>
                  </td>
                )}

                <td className={`p-0 ${subOrderIndex === subOrder.items.length - 1 ? "border-none" : "border-bottom"}`}>
                  <div className="d-flex flex-column gap-2">
                    <div
                      className={`d-flex gap-2 flex-column p-3 ${
                        subOrderIndex === subOrder.items.length - 1 || subOrderIndex === order.subOrders.length - 1
                          ? "border-none"
                          : "border-bottom"
                      }`}
                    >
                      {itemIndex === 0 && subOrderIndex === 0 && (
                        <strong style={{ fontSize: "16px" }}>
                          <Link to={`/reserve/${order.id}`}>#{order?.id}</Link>
                        </strong>
                      )}
                      <div className="d-flex gap-4 align-items-start w-100">
                        <PhotoProvider>
                          <div
                            style={{
                              backgroundColor: "rgb(239, 239, 239)",
                              minHeight: "80px",
                              width: "80px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "14px",
                            }}
                          >
                            {item?.variation?.files
                              .sort((a, b) => a.order - b.order)
                              ?.map((image, index) =>
                                index === 0 ? (
                                  <PhotoView key={index} src={image?.url}>
                                    <img
                                      style={{
                                        cursor: "pointer",
                                        borderRadius: "6px",
                                        width: "100%",
                                        maxHeight: "80px",
                                        objectFit: "contain",
                                      }}
                                      src={image?.url}
                                      alt={image?.id}
                                    />
                                  </PhotoView>
                                ) : null
                              )}
                          </div>
                        </PhotoProvider>
                        <div className="d-flex flex-column justify-content-between" style={{ height: "80px" }}>
                          <Link
                            style={{
                              fontSize: "15px",
                              fontWeight: 600,
                              overflow: "hidden",
                              whiteSpace: "wrap",
                              maxWidth: "100%",
                            }}
                            to={`/products/${item?.variation?.product?.id}`}
                          >
                            {item?.variation?.product?.name}
                          </Link>
                          <div style={{ fontSize: "14px", fontWeight: 500, textAlign: "left" }}>
                            {t("quantity")}: {item?.count}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  {prettify(
                    item?.variation?.prices?.find((price) => price.type === "Price")?.value * item?.count
                  ) +
                    " " +
                    t(currency === "aed" ? "AED" : currency)}
                </td>

                <td>{paymentType(order?.paymentType)}</td>
                <td>{statusLabel(order?.status)}</td>

                {itemIndex === 0 && subOrderIndex === 0 && (
                  <td rowSpan={totalItems}>
                    <div
                      style={{
                        position: "sticky",
                        top: 50,
                        zIndex: 99,
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        style={{ background: "#D5D5D5" }}
                        color="none"
                        size="sm"
                        outline
                        onClick={() => navigate(`/reserve/${order.id}`)}
                      >
                        {t("read_more")}
                      </Button>
                    </div>
                  </td>
                )}
              </tr>
            ))
          )}
        </React.Fragment>
      );
    })
  ) : (
    <tr className="text-center">
      <td colSpan={10}>{t("no_data")}</td>
    </tr>
  )}
</tbody>

        </Table>
      </div>
      {pagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => pagination?.onChange(pagination.currentPage - 1)}
                disabled={pagination?.currentPage <= 1}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pagination?.currentPage} of {pagination?.totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pagination?.totalPages}
              defaultValue={pagination?.currentPage}
              readOnly
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => pagination?.onChange(pagination.currentPage + 1)}
                disabled={
                  pagination?.totalPages === pagination?.currentPage ||
                  !data?.length
                }
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AccordionReserve;
