import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

const PageSize = ({setFormValues, tableKey,formValues, sizeKey, pageKey}) => {
    const { t } = useTranslation();
    useEffect(() => {
        const storedPageSize = JSON.parse(localStorage.getItem("pageSizes")) || {};
        const pageSize = storedPageSize[tableKey];
        if (pageSize) {
          setFormValues((prevState) => ({
            ...prevState,
            [sizeKey]: pageSize,
          }));
        }
      }, []);
    
    const handlePageSizeChange = (e) => {
        const newSize = parseInt(e.target.value);
        setFormValues((prevState) => ({
          ...prevState,
          [sizeKey]: newSize,
          [pageKey]: 1,
        }));
        const storedPageSizes = JSON.parse(localStorage.getItem("pageSizes")) || {};
        storedPageSizes[tableKey] = newSize;
        localStorage.setItem("pageSizes", JSON.stringify(storedPageSizes));
      };
    
  return (
    <div>
      <Input
        type="select"
        name="pageSize"
        id="pageSize"
        value={formValues?.[sizeKey]}
        onChange={handlePageSizeChange}
        className="w-auto"
      >
        <option value={20}>{t("20")}</option>
        <option value={50}>{t("50")}</option>
        <option value={100}>{t("100")}</option>
      </Input>
    </div>
  );
};

export default PageSize;
